/* ==========================================================================
   Badges
   ========================================================================== */

.o-badge {
    color: white;
    background-color: black;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    display: inline-block;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
}
