/* ==========================================================================
   Satelite Venues
   ========================================================================== */

.satelite-branding {
    @include clearfix();
    background-color: #b1b3b5;
    margin: 10px 0;
    display: flex;
    align-items: center;
}
.satelite-branding__icon {
    margin-right: 10px;
    float: left;
    height: 50px;
    border-right: 4px solid #201d1e;
    background-color: #201d1e;
}
.satelite-branding__name{
    text-transform: uppercase;
    font-family: font-stack(trim);
    float: left;
    display: inline-block;
    padding: 0 4px;
    color: get-color(brand, secondary);

    @include mq(small){
        font-size: 24px;
    }
    @include mq(medium){
        font-size: 28px;
    }
}
