/* ==========================================================================
   Social
   ========================================================================== */

// .share-tool {}
.share-tool__button {
    opacity: 0.75;
    font-size: 12px;
    padding: 0;
    margin: 0 0 15px 0;
    cursor: pointer;
    font-family: font-stack(sans);
    text-transform: none;

    &:hover,
    &.is-toggled {
        opacity: 1;
    }

    .icon-share {
        width: 18px;
        height: 20px;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}
.share-tool__button-text {
    display: block;
    float: right;
    line-height: 23px;
    height: 21px;
    margin-left: 5px;
}
.share-tool__box {
    @include reset-list();
    background: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    display: none;
    margin-left: -4%;
    margin-right: -4%;

    @include mq(small-major) {
        position: absolute;
        z-index: z-scale(overlay);
        box-shadow: 0 2px 2px rgba(0,0,0,0.4);
        margin: 0;
    }

    &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #333;
        position: absolute;
        margin-top: -20px;
        left: 20px;
    }

    &.is-toggled {
        display: block;
    }
}
.share-tool__item {
    display: inline-block;
}
.share-tool__link {
    display: block;
    padding: 5px 8px;
    opacity: 0.85;

    @include mq(small-major) {
        .o-icon {
            width: 20px;
            height: 20px;
        }
    }
}
.share-tool__link:hover {
    opacity: 1;
}
.share-tool__link.facebook {
    background-color: #3b5998;
}
.share-tool__link.twitter {
    background-color: #55acee;
}
.share-tool__link.google-plus {
    background-color: #dc4e41;
}
.share-tool__link.js-copy-to-clipboard {
    background-color: #666;
    border: none;
}
.share-tool__link .o-icon{
    width: 30px;
    height: 30px;
}

/**
 * Share tool: Inverted
 */
.share-tool--invert {
.share-tool__button {
        color: white;
    }
}

/**
 * Share tool: Centered
 */
.share-tool--center {
    width: 175px;
    margin: auto;

    .share-tool__box:before {
        left: calc(50% - 10px);
    }
}

/**
 * Share embeds: Massey Hall Venue page

 -- EmbedSocial Settings --

 Posts per page: 1,
 Number of columns: 1,
 Padding size: none,
 Layout: Masonry
 For header settings, untick title, follow button, thumbnail image, number of posts
 Custom CSS:
  .container-fluid {padding: 0;} .container-fluid {padding: 0;} .new-espagination>.active>a {background: #da3d39;border-radius: 0 !important;} .new-espagination>.active>a:hover {background: #bd2723 !important; opacity: 1;} .new-espagination>li>a:hover {border-radius: 0; color: black;}
**/

.social-media-grouping {
    margin-bottom: $global-gutter;
    border: 1px solid black;
    padding: 20px;
}

.social-media-grouping__container {
    display: flex;
    flex-direction: column;

    @include mq(medium) {
        flex-direction: row;
        justify-content: space-evenly;
        grid-gap: 1em;
    }

    @include mq(large) {
        grid-gap: 1.5em;
    }
}

.social-media-grouping__feed {
    margin: 0 0 $global-baseline 0;
    width: 100%;

    &:last-of-type {
        margin: 0;
    }

    @include mq(medium) {
        margin: 0 0 $global-baseline 0;
    }
}

.social-media-grouping__title {
   margin-bottom: 1em;
}

.social-media-grouping__heading {
    @include mq(medium) {
       font-size: 18px;
    }

    @include mq(large) {
       font-size: 20px;
    }
}

.social-media-grouping__link {
    @include mq(medium) {
       font-size: 12px;
    }

    @include mq(large) {
       font-size: 14px;
    }
}

.social-media-grouping__heading-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 10px;
}
