/* ==========================================================================
   Utilities: Tones
   ========================================================================== */

@mixin blockColors($color) {
    $light: get-color(text, reversed);
    $dark: get-color(text);

    color: contrasting($color, $light, $dark);
    background-color: $color;
    border-color: currentColor;
}

.u-tone-primary {
    color: $primaryColor;
}
.u-tone-bg-tint {
    @include blockColors(get-color(neutral, light));
}
.u-tone-bg-primary {
    @include blockColors($primaryColor);
}
