/* ==========================================================================
   Asides
   ========================================================================== */

.aside {
    margin-bottom: 1em;
}
.aside__title {
    font-size: 20px;
    margin-top: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid get-color(global, border);
}
.aside__header,
.aside__body {
    padding: .5em 0 0;
}
.aside__body {
    padding-bottom: .5em;
    font-size: 14px;
}

.aside--panel {
    background-color: get-color(neutral, light);
    padding: $global-baseline;
    margin-bottom: $global-baseline;

    .aside__header {
        padding: 0;
    }

    .aside__title {
        border-bottom: none;
        padding-bottom: 0;
    }
}
.aside--reversed {
    color: white;
    background-color: get-color(brand, secondary);
    border-color: rgba(255, 255, 255, 0.5);
}

.aside--sponsors{
    @include reset-list();

    &.has-title{
        .aside__sponsors-list-item:first-child .sponsor{
            border-top: none;
            padding-top: 0;
            margin-top: 0;
        }
    }
}

.aside--center{
    text-align: center;

    img{ margin: auto; }
}

.aside--links {
    .o-block-list__link {
        font-size: 16px;
        padding-left: 24px;
    }
    .o-icon {
        width: 16px;
        height: 16px;
        color: $primaryColor;
    }
}

.aside--downloads {
    background-color: $primaryColor;
    padding: 16px;

    .aside__header {
        padding: 0;
    }
    .aside__title {
        padding-bottom: 0;
        border-bottom: none;
        color: #fff;
    }
    .o-block-list__item {
        margin-bottom: 12px;
        position: relative;
        padding-left: 24px;
    }
    .o-block-list__link {
        color: #fff;
        padding-left: 0;
        display: inline;
        line-height: 1.4em;
        font-size: 16px;
        font-weight: 600;
    }
    .o-icon {
        width: 19px;
        height: 19px;
        color: get-color(text);
        top: 2px;
        left: -2px;
        position: absolute;
    }
    .filesize {
        font-size: 11px;
        display: inline;
        margin-left: 2px;
        color: #fff;
        white-space: nowrap;
    }
}
