/* ==========================================================================
   Breadcrumb
   ========================================================================== */

.breadcrumb {
    font-size: 85%;
    padding: 10px 0;
    color: white;
    background: get-color(brand, secondary);
    font-family: font-stack(sans);
}
.breadcrumb__list {
    @include reset-list();
    margin-top: 0;
    margin-bottom: 0;
}
.breadcrumb__item {
    display: none;
    margin-left: 0;
    font-size: 12px;

    &.next-last {
        display: inline-block;
        &:before {
            content: '\2190';
        }
    }
}
.breadcrumb__link {
    font-weight: normal;
}
.breadcrumb__item.is-current {
    font-weight: bold;
}

@include mq(small) {
    .breadcrumb__item {
        display: inline-block;
        span{ opacity: 0.5; }
        &:after {
            content: '\203A';
            padding: 0 0.25em 0 0.5em;
        }
        &.next-last:before{
            display: none;
            content: '';
        }
        &:last-child:after{
            display: none;
            content: '';
        }
        &.is-current, &:hover{
            span{ opacity: 1; }
        }
    }
}
