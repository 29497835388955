/* ==========================================================================
   Production
   ========================================================================== */
/* ==========================================================================
   Production - Header
   ========================================================================== */


.production-header {
    background-color: get-color(brand, secondary);
    color: #fff;

}
.production-header__branding {
    padding: 0 2%;
    background-color: get-color(brand, secondary);
    border-bottom: 4px solid $primaryColor;
}
.production-header__content {
    padding: $global-baseline $global-gutter;
    padding-bottom: 0;
}
.production-header__title {
    margin: .25rem 0;
    text-transform: uppercase;
    @include font-scale(display, 5);

}
.production-header__date-description {
    text-transform: uppercase;
    color: $primaryColor;
    margin: 20px 0;
    padding-bottom: 20px;
    border-bottom: 4px solid $primaryColor;
    font-size: 14px;
    @include mq(medium) {
        font-size: 16px;
    }
    @include mq(large) {
        font-size: 18px;
    }
    font-family: font-stack(domaine);

    @if $theme == "roythomsonhall" {
        font-family: font-stack(trim);
    }
}
.production-header .date-seperator {
    padding: 1px 9px 4px 6px;

    @if $theme == "roythomsonhall" {
        padding-top: 5px;
    }
}
.production-header .date-seperator .icon-diamond {
    width: 10px;
    height: 10px;
}
.production-header__prefix,
.production-header__suffix {
    font-family: font-stack(domaine);
    font-size: 12px;
    line-height: 1rem;
    @include mq(medium) {
        font-size: 14px;
    }
    @include mq(large) {
        font-size: 18px;
    }
}

.production-header__suffix .suffix-item {
    white-space: nowrap;
    margin-right: .75rem;
}

.production-header__suffix .suffix-item:last-child {
    margin-right: 0;
}


.production-header__ticket-info {
    @include clearfix();
    width: 100%;
}
.production-header__availability {
    margin-right: 20px;
    display: inline-block;
    float: left;
}
.production-header__availability-message {
    display: block;
    text-align: center;
    padding: 0 0 5px;
    margin-top: -5px;
}
.production-header__availability {
    .performance-select{
        display: block;
        padding: 8px 0;

        .book-button{
            border-color: #fff;
            color: #fff;
            &:hover{
                background-color: #fff;
                color: #201d1e;
            }
        }
        .book-button__icon{
            background-color: #fff;
            color: #201d1e;
        }
    }
}
.front-of-the-line {
    clear: left;
    display: block;
}
.front-of-the-line{
    .book-button {
        color: #fff;
        border-color: #fff;
    }

    .book-button:hover .book-button__text{
        background: #fff;
        color: get-color(brand, secondary);
    }
}
.front-of-the-line .book-button__icon {
    color: get-color(brand, secondary);
    background-color: #fff;
}
.production-header__promo-code {
    display: block;
    margin: 12px 0;
    clear: left;
    line-height: 1em;

    &:hover{
        text-decoration: underline;
    }
}
.production-header__price {
    display: inline-block;
    font-weight: bold;
    font-family: font-stack(trim);
    font-size: 22px;
    margin-bottom: 10px;
    float: left;
}
.production-header__social {
    @include clearfix();
    margin-top: 20px;
    position: relative;
    clear: left;
}
.production-header__hashtag {
    position: absolute;
    right: 0;
    top: 0;
    font-family: font-stack(sans);
    font-size: 12px;
    font-weight: bold;
}
.production-header__social .share-tool__button {
    margin-bottom: 1em;

    &.is-toggled{
        margin-bottom: 15px;
    }
}
.production-header.has-satelite-venue {
    .production-header__branding {
        border-bottom-color: #b1b3b5;
    }

    .production-header__date-description {
        color: #b1b3b5;
        border-bottom-color: #b1b3b5;
    }
}
.production-header.has-image {
    @include clearfix();

    .media-image, .media-image img {
        width: 100%;
    }

    @include mq(medium) {
        .production-header__media {
            width: 43%;
            float: right;
            margin-right: 5%;
            max-width: 800px;
            &.is-transparent {
                margin-top: -5.95%;
                @include mq(xlarge) {
                    margin-top: -2.95%;
                }
            }
        }
        .production-header__content {
            width: 52%;
            float: left;
        }
    }
    @include mq(xlarge) {
         .production-header__media {
            margin-right: 0;
            width: 40%;
        }
        .production-header__content {
            padding: 0 5% 16px 0;
            width: 60%;
        }
    }
}

@include mq(medium) {
    .production-header__content {
        padding: 0 $global-gutter $global-baseline;
    }

    .production-header__branding {
        margin: 0 5% 20px;
        padding: 2% 0 20px;
    }
    .production-header__hashtag {
        float: left;
        position: relative;
        top: auto;
    }
    .production-header__social .share-tool {
        float: left;
        margin-right: 30px;
    }
}

@include mq(large) {
    .production-header__title { @include font-scale(display, 7); }
}
@include mq(xlarge) {
    .production-header__inner{ @include constrained(); }
    .production-header__branding { margin: 0 0 20px; }
}



/* ==========================================================================
   Production - Header Small
   ========================================================================== */

.production-header--small{
    position: fixed;
    z-index: z-scale(navigation);
    width: 100%;
    bottom: 0;
    padding: 10px 0;
    text-align: center;
    background-color: #fff;
    color: get-color(text);
    border-top: 1px solid #ccc;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease;

    body.show-production-header & {
        opacity: 1;
        visibility: visible;
    }

    .production-header__inner{ @include constrained(); }
    .production-header__title{
        @include font-scale(display, 2)
        color: get-color(brand, secondary);
        display: none;
    }
    .production-header__date-description{
        font-size: 14px;
        margin: 5px 0 0;
        padding-bottom: 0;
        border-bottom: 0;
        display: none;
    }
    .production-header__availability{
        float: none;
        display: block;
        .booking-container{ margin-bottom: 0; }
    }
    .production-header__availability-message {
        display: none;
    }
    .book-button:hover{ color: #fff; }
    .book-button__icon{ color: #fff; }

    .book-button:not([disabled]){
        color: $primaryColor;
        border-color: $primaryColor;
        .book-button__icon{
            background-color: $primaryColor;
            color: #fff;
        }
        &:hover{
            background-color: $primaryColor;
            color: #fff;
        }
    }
    .production-header__availability .performance-select{
        color: #fff;
        .book-button__icon{ color: #fff; }
        .book-button:hover{ color: #fff; }
    }
    @include mq($until: medium) {
        .production-header__availability{ margin-right: 0; }
        .booking-container{ display: block; }
        .book-button{
            display: block;
            width: 100%;
            text-align: center;
        }
        .book-button[disabled]{
            background-color: get-color(neutral, dark);
            color: #fff;
        }
        .book-button:not([disabled]){
            background-color: $primaryColor;
            color: #fff;
            &:hover{
                background-color: darken($primaryColor, 10%);
                border-color: darken($primaryColor, 10%);
            }
        }
        .book-button__icon{ display: none; }
        .book-button__text{ float: none; }

        .performance-select__date{
            display: block;
            width: 100%;
            margin-bottom: 5px;
        }
    }
    @include mq(medium) {
        text-align: right;
        .production-header__title{ @include font-scale(display, 3); }
        .production-header__title,
        .production-header__date-description{
            display: block;
        }
        .production-header__availability{
            float: right;
            margin: 0 0 0 20px;
        }
    }
    @include mq(medium-major) { width: calc(100% - 180px); }
    @include mq(xlarge) { width: calc(100% - 200px); }
}


/* ==========================================================================
   Production - Details
   ========================================================================== */

.production-content__details {
    padding: 2em 0 0 0;
    @include mq(medium) {
        padding: 2em 0 1em 0;
    }
}
.production-content__info {
    @include mq(medium) {
        padding: 3em 0;
    }
}


.related-series {
    @include clearfix();
    margin: 2em 0;
    border: 1px solid get-color(global, border);
    padding: .5em;
    display: table;
    width: 100%;

    .s-prose &{
        a:not(.inline-button), a:not(.inline-button):hover{ text-decoration: none; }
    }
}
.related-series__prefix{
    font-style: italic;
}
.related-series__body {
    padding: .5em;
    display: table-cell;
    vertical-align: middle;
}
.related-series__title {
    font-size: 16px;
    .s-prose &{ margin: 0; }
}
.related-series__media {
    margin-left: 2%;
    display: block;
    text-align: right;
    width: 120px;
    float: right;
}

.related-series__read-more {
    font-size: 16px;
    display: inline-block;
}

@include mq(medium) {
    .related-series__title {
        font-size: 22px;
    }
}

/* ==========================================================================
   Onsale Dates
   ========================================================================== */

.onsale-dates {
    margin-bottom: 10px;
}
.onsale-dates__row {
    border-bottom: 1px solid;
    border-color: inherit;
}
.onsale-dates__title,
.onsale-dates__datetime {
    padding: 3px 0;
    line-height: 1.2;
}
.onsale-dates__title {
    padding-right: 5px;
}
.onsale-dates__datetime {
    text-align: right;
    font-weight: bold;
    white-space: nowrap;
}

/* ==========================================================================
   Part Of
   ========================================================================== */
.part-of {
    margin-top: .5em;
    padding-top: 1em;
    border-top: 1px solid get-color(global, border);
}
.part-of__inner{
    display: table;
    margin: auto;
}
.part-of__media{
    display: table-cell;
}
.part-of__image{
    width: 100%;
}
.part-of__content{
    display: table-cell;
    padding-left: 10px;
    vertical-align: middle;
}
.part-of__title{
    font-size: 20px;
}
.part-of a{
    color: $primaryColor;
    font-weight: bold;
    &:hover{ text-decoration: underline; }
}

@include mq(medium) {
    .part-of__inner { width: 100%; }
}


/* ==========================================================================
   Production - Event Panel
   ========================================================================== */

.event-panel {
    background-color: get-color(lamh, primary);
    color: #fff;
    overflow: hidden;
    position: relative;

    & > .u-constrained {
        width: 100% !important;
        position: relative;
    }
}
.event-panel__media {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .3;
    position: absolute;

    .event-panel__image {
        width: 100%;

    }
}
.event-panel__body {
    padding-top: 30px;
    padding-bottom: 50px;
    position: relative;
    padding-left: 2%;
    padding-right: 2%;
}

.event-panel__small-print {
    font-size: 12px;
    font-style: italic;
    margin-top: 1.5em;
}

@include mq(small) {
    .event-panel__body {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@include mq(medium-major) {
    .event-panel__media {
        width: 50%;
        opacity: 0.9;
    }
    .event-panel__body {
        padding-left: 48%;
        padding-right: 0;
    }
}


/* ==========================================================================
   Production - Venue Panel
   ========================================================================== */

.venue-panel {
    padding: 40px 0;
}
.venue-panel__header {
    @include clearfix();
    background-color: $primaryColor;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
}
.venue-panel__logo {
    background-color: #fff;
    border-right: 5px solid #fff;
    border-left: 5px solid #fff;
    float: left;
    width: 60px;
    height: 50px;
}
.venue-panel__title {
    float: left;
    height: 50px;
    line-height: 50px;
    margin: 0;
    padding: 0 10px;
    text-transform: uppercase;
    font-family: font-stack(trim);
    color: #fff;
    font-size: 15px;
}
.venue-panel__body {
    width: 100%;
}
.venue-panel__media {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 200px;
}
.venue-panel__details {
    background-color: lighten($primaryColor, 40%);
    padding: 2em;
}
.venue-panel__contact {
    margin-bottom: 1em;
    float: left;
    width: 50%;
    padding-right: 2%;
}
.venue-panel__contact h3 {
    font-size: 16px;
}
.venue-panel a {
    color: $primaryColor;
    font-weight: bold;
    clear: both;
    display: block;

    &:hover{
        text-decoration: underline;
    }
}

.venue-panel--satellite{
    .venue-panel__header{
        background-color: #b1b3b5;
    }
    .venue-panel__details {
        background-color: #e3e4e5;
    }
    .venue-panel__title {
        color: get-color(text);
    }
}


@include mq(small) {
    .venue-panel__logo {
        width: 75px;
        height: 70px;
    }
    .venue-panel__body {
        display: flex;
    }
    .venue-panel__media {
        width: 50%;
        border-right: 5px solid #fff;
        padding: 16% 0;
    }
    .venue-panel__title {
        font-size: 20px;
        height: 70px;
        line-height: 70px;
        padding: 0 20px;
    }
    .venue-panel__details {
        width: 50%;
    }
    .venue-panel__contact {
        float: none;
        width: 100%;
    }
}
@include mq(medium) {
    .venue-panel__title {
        font-size: 35px;
    }
}
@include mq(medium-major) {
    .venue-panel{
        @include constrained();
    }
    .venue-panel__logo { border-left: 0; }

}



/* ==========================================================================
   Production - Recommended Events
   ========================================================================== */

.recommended-events {
    .recommended-events__inner {
        @include constrained();
        padding-top: .5em;
        border-top: 1px solid get-color(global, border);
    }

    @include mq(medium) {
        background-color: get-color(neutral, light);

        .recommended-events__inner {
            padding-top: 0;
            border-top: none;
        }

        .recommended-events__title {
            @include font-scale(display, 4);
            padding: 1em 0 0;
            margin: 0;
        }

        .event-item {
            display: block;
            border-bottom: none;
        }
        .event-item.has-image {
            .event-item__content,
            .event-item__media {
                display: block;
                padding: 0;
                float: none;
            }
            .event-item__media {
                width: 100%;
                padding-left: 0;
                margin-bottom: .5em;
            }
            .event-item__content{ padding-right: 0; }
            .event-item__image {
                width: 100%;
            }
        }
        .booking-container.has-multiple-dates{
            width: 180px;
            float: left;
            display: block;
        }
        .performance-select__date{
            margin-bottom: 5px;
            max-width: 175px;
        }
    }
}

.performance-details--small {
    display: block;
    margin: 1px -5%;
    .aside-ticket-sales,
    .aside-performance-info {
        padding: 16px 5%;
    }
    .aside-performance-info {
        border-bottom: 1px solid #333;
    }
    @include mq(medium) {
        display: none;
    }
}

.performance-details--large {
    display: none;
    @include mq(medium) {
        display: block;
    }
}
