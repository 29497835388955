// =============================================================================
// Mixins
// =============================================================================

@import "bower_components/sass-mq/mq";

// =============================================================================
// Site-specific mixins
// =============================================================================

/**
 * Reset list
 */
@mixin reset-list() {
    list-style: none;
    margin: 0;
    padding: 0;
}

/**
 * Text trunctation
 */
@mixin truncate($width: 100%) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: $width;
}

/**
 * Micro-clearfix
 */
@mixin clearfix() {
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

/**
 * Hide text from browsers but make it available for screen readers
 */
@mixin visually-hidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin constrained() {
    width: 100% - ($global-gutter * 2);
    max-width: $global-width-maximum;
    margin-left: auto;
    margin-right: auto;

    @if $mq-responsive == false {
        width: 1000px;
    }
}

@mixin well() {
    box-sizing: border-box;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 6%;
    padding-right: 6%;
    background-color: get-color(neutral, light);
    border: 1px solid darken(get-color(neutral, light), 10%);
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: -6%;
    margin-right: -6%;

    & > p:only-child,
    & > p:last-child {
        margin-bottom: 0;
    }

    @include mq(medium) {
        margin: 1em 0;
        padding: 1em;
    }
}

@mixin blockquote() {
    margin: 0;
    padding: .5em 0 .5em 1em;
    border-left: 5px solid get-color(neutral);
}
