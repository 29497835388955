/* ==========================================================================
   Live at Massey Hall
   ========================================================================== */

   .live-at-massey{
    background-color: #000;
}


/* ==========================================================================
   Live at Massey Hall - Feature Slider
   ========================================================================== */

.venue-hero.lamh-slider {
    padding: 0;
    position: relative;
    min-height: inherit !important;
    background-color: black;

    .video-item__inner {
        width: 100% !important;
        max-width: inherit !important;
    }
}

.lamh-slider {
    border-bottom: 1px solid #fff;
}
.lamh-slider .slick-dots {
    bottom: 0;

    button {
        background-color: rgba(white, .65);
        opacity: 1;
    }

    button:hover,
    button:focus,
    .slick-active button {
        background-color: #fff;
        outline-color: transparent;
    }

    button:focus {
        outline-color: $primaryColor;
        outline-offset: 2px;
    }

    button:focus:not(:focus-visible) {
        outline-color: transparent;
    }
}

.lamh-slider__introduction {
    position: relative;
    padding-bottom: 60px;
}
.lamh-slider__introduction-inner{
    @include mq(xlarge) {
        @include constrained();
        position: relative;
    }
}
.lamh-slider__introduction-text {
    width: 100%;
    color: #fff;
    text-align: center;
    margin-top: -70px;
}
.lamh-slider__introduction-text p {
    display: inline-block;
    width: 90%;
    max-width: 600px;
    font-size: 16px;
    background-color: rgba(0,0,0,.7);
    padding: 20px;
}
.lamh-slider__introduction-image {
    width: 100%;
}
@include mq(small-major) {
    .lamh-slider__introduction-text p {
        font-size: 22px;
    }
}
@include mq(medium) {
    .lamh-slider__introduction {
        padding-bottom: 0;
    }
    .lamh-slider__introduction-text {
        position: absolute;
        bottom: 60px;
    }
    .lamh-slider__introduction-text p {
        width: 65%;
    }
}
@include mq(large) {
    .lamh-slider__introduction-text {
        bottom: 100px;
    }
}

.lamh-slider {
    .video-landing-event-item--featured,
    .video-item--featured {
        background-color: transparent;
        display: block;
        position: relative;
        padding: 0;
        z-index: 900;
        width: 100%;
        color: #fff;
        margin-bottom: 0;

        .video-landing-event-item__media,
        .video-landing-event-item__content,
        .video-item__media,
        .video-item__content {
            display: block;
            width: 100%;
            float: none;
        }
        .video-landing-event-item__media,
        .video-item__media {
            padding: 0;
            position: relative;
            margin-bottom: 0;
        }
        .video-landing-event-item__image,
        .video-item__image{
            width: 100%;
        }
        .video-landing-event-item__title,
        .video-item__title {
            font-size: 20px;
            margin-bottom: 10px;
        }
        .video-landing-event-item__content,
        .video-item__content {
            padding: 20px 20px 60px 20px;
        }
        .video-landing-event-item__description,
        .video-item__description {
            font-size: 14px;
        }
        .video-item__meta {
            margin-bottom: 5px;
        }
        .video-landing-event-item p,
        .video-item__description p {
            margin-bottom: 0;
        }
        .video-landing-event-item__link,
        .video-item__link {
            position: absolute;
            right: 0; top: 20px;
            display: block;
            text-align: right;
            opacity: 0;
            transition: all .5s ease-in-out;
            z-index: z-scale(overlay);

            .icon-play {
                width: 40px;
                height: 40px;
            }
        }
        @include mq(small-major) {
            .video-landing-event-item__content,
            .video-item__content {
                position: absolute;
                padding: 15px 20px;
                bottom: 20px;
                left: 20px;
                width: 65%;
                background-color: rgba(0,0,0,.7);
                opacity: 0;
                z-index: z-scale(overlay);
                transition: all .5s ease-in-out;
            }
            .video-landing-event-item__link,
            .video-item__link {
                padding-top: 50px;
                bottom: 80px;
                top: auto;
            }
        }
        @include mq(medium) {
            .video-landing-event-item__content,
            .video-item__content {
                width: 50%;
            }
            .video-landing-event-item__title,
            .video-item__title {
                font-size: 30px;
            }
            .video-landing-event-item__description,
            .video-item__description {
                font-size: 18px;
            }
            .video-item__link .icon-play {
                width: 90px;
                height: 90px;
            }
        }
        @include mq(xlarge) {
            .video-item__inner{
                @include constrained();
                position: relative;
                &:before, &:after{
                    content: "";
                    width: 300px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    z-index: z-scale(foreground);
                }
                &:before{
                    left: 0;
                    background: linear-gradient(to left, transparent 0%, #000 100%);
                }
                &:after{
                    right: 0;
                    background: linear-gradient(to right, transparent 0%, #000 100%);
                }
            }
        }

    }
    .video-landing-event-item--featured.slick-active,
    .video-item--featured.slick-active {
        .video-landing-event-item__content,
        .video-item__content {
            opacity: 1;
            transition: all .5s ease-in-out;
            transition-delay: .75s;
            bottom: 60px;
        }
        .video-landing-event-item__link,
        .video-item__link {
            opacity: 1;
            right: 20px;
            transition: all .5s ease-in-out;
            transition-delay: .75s;
        }

        @include mq(medium) {
            .video-landing-event-item__link,
            .video-item__link {
                right: 50px;
            }
        }
    }
}





/* ==========================================================================
   Live at Massey Hall - Season Slider
   ========================================================================== */

.season-navigation {
    color: #fff;
    padding-top: 2em;
    padding-bottom: .5em;
    background: #000;
}
.season-navigation__header{
    @include clearfix();
}
.season-navigation__title{
    float: left;
}
.season-navigation__view-all-seasons{
    float: right;
    background-color: #fff;
    color: #232323;
    border-color: #fff;
    opacity: 0.75;
    @include mq($until: small) {
        font-size: 10px;
        height: 21px;
        margin-top: 5px;
    }
    &:hover{
        opacity: 1;
        background-color: #fff;
        border-color: #fff;
        color: #232323;
    }
}
.season-slider {
    opacity: 0;
    transition: opacity 1s ease;

    &.slick-initialized{
        opacity: 1;
    }
    .slick-list {
        padding: 20px 40px;
    }
    .slick-prev, .slick-next {
        z-index: z-scale(foreground);
        display: block;
        text-indent: -999999px;
        overflow: hidden;
        -webkit-appearance: none;
        background-color: rgba(0,0,0,0.5);
        border: none;
        width: 38px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 20px;
        bottom: 20px;
    }

    .slick-prev { left: 0; }
    .slick-next { right:0; }

    &:hover {
        .slick-next { background-image: url(../images/icon-next-white.svg); }
        .slick-prev { background-image: url(../images/icon-prev-white.svg); }
    }
    @include mq($until: medium) {
        .slick-next { background-image: url(../images/icon-next-white.svg); }
        .slick-prev { background-image: url(../images/icon-prev-white.svg); }
    }
    .video-item__description{ display: none; }

    .video-item--large {
        background-color: transparent;
        display: block;
        position: relative;
        padding: 0;
        margin: 0 2px;
        z-index: 900;
        transition: transform .25s ease-in-out;


        .video-item__media,
        .video-item__content {
            display: block;
            width: 100%;
        }
        .video-item__media {
            padding: 0;
            position: relative;
            margin-bottom: 0;
        }
        .video-item__content {
            position: absolute;
            padding: 15px 20px;
            bottom: 0;
            left: 0;
            right: 0;
            width: auto;
            z-index: 300;
            background-color:rgba(0,0,0,0.75);
        }
        .video-item__meta {
            margin-bottom: 5px;
            font-size: 12px;
        }
        .video-item__description p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 1.2;
        }
        .video-item__link {
            position: absolute;
            top: 20px;
            right: 20px;
            opacity: 1;

            .icon-play {
                width: 30px;
                height: 30px;
            }
        }
        @include mq(medium-major) {
            .video-item__content {
                opacity: 0;
                z-index: 300;
                transition: opacity .25s ease-in-out;
            }
            .video-item__media:after {
                opacity: 0;
            }
            .video-item__description{ display: block; }
            .video-item__link {
                opacity: 0;
                transition: all .25s ease-in-out;
            }
        }
        @include mq(large) {
            .video-item__link {
                width: 100%;
                height: 100%;
                top: 0; bottom: 0;
                left: 0; right: 0;
                display: block;
                text-align: center;
                padding-top: 50px;

                .icon-play {
                    width: 68px;
                    height: 68px;
                    opacity: .25;
                }
                &:hover .icon-play {
                    opacity: 1;
                    fill: #fff;
                    color: $primaryColor;
                }
            }
        }
    }
    .video-item:hover {
        @include mq(medium-major) {
            transform: scale(1.1);
            z-index: 1000;

            .video-item__content {
                opacity: 1;
            }
            .video-item__link {
                opacity: 1;
            }
        }
        @include mq(large) {
            .video-item__link {
                padding-top: 50px;
            }
        }
    }

// on hover first child offsets to the right and third child offsets to the left

    @include mq(medium-major) {
        .slick-active:nth-child(3n+1):hover {
            transform: scale(1.1) translateX(16px);
        }
        .slick-active:nth-child(3n+3):hover {
            transform: scale(1.1) translateX(-16px);
        }
    }
}


/* ==========================================================================
   Live at Massey Hall - Video Page
   ========================================================================== */

   /* .video-page */

.video-header {
    background-color: #000;
    text-align: center;
}
.video-header__video {
    display: inline-block;
    max-width: 100%;
}
@include mq(large) {
    .video-header {
        padding: 0 15%;
    }
}

.video-page{
    @include clearfix();
}

.video-page__primary, .video-page__secondary{
    margin-bottom: $global-baseline;
}
.video-page__primary{ padding-top: ($global-baseline * 2); }
.video-page__secondary{ padding-top: $global-baseline; }

@include mq(medium) {
    .video-page__primary, .video-page__secondary{
        padding: ($global-baseline * 3) 0 0;
    }
    .video-page__primary{
        float: left;
        width: 57%;
        margin-left: 0;
        margin-right: 3%;
    }
    .video-page__secondary{
        float: right;
        width: 40%;
    }
}
@include mq(large) {
    .video-page__primary{ width: 62%; }
    .video-page__secondary{ width: 35%; }
}
@include mq(xlarge) {
    .video-page__primary{ width: 67%; }
    .video-page__secondary{ width: 30%; }
}



.video-page__meta {
    margin: .5em 0 1.5em 0;
    display: block;
}
.video-page__description {
    font-size: 20px;
}




/* ==========================================================================
   Live at Massey Hall - Video Page Credits
   ========================================================================== */

.video-credits {
    background-color: get-color(neutral, light);
    padding: 0 1em 1em 1em;
    display: table;
    width: 100%;
    text-align: center;
    margin-bottom: 2em;
}

.video-credits__group {
    display: table-cell;
    width: 50%;
    padding-right: 20px;
}

.video-credits__role {
    font-size: 12px;
    margin-top: 1em;
}
.video-credits__person {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
}

@include mq(medium) {
    .video-page__credits {
        text-align: left;
    }
}

/* ==========================================================================
   Live at Massey Hall - Video Page Sponsors
   ========================================================================== */

.video-sponsors{
    padding: 1em 0;
    border-top: 1px solid get-color(global, border);

    .sponsor{
        border-top: none;
        padding-top: 0;
        margin-top: 0;
    }
    .video-sponsors__item{
        display: block;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .sponsor__description, .sponsor__media{
        vertical-align: bottom;
    }
    .sponsor__media{
        display: inline-block;
    }
    .sponsor__description{
        font-size: 12px;
    }

    @include mq(small) {
        .sponsor{
            display: table;
            text-align: left;
        }
        .video-sponsors__item{
            display: inline-block;
            width: auto;
        }
        .sponsor__description, .sponsor__media{
            display: table-cell;
        }
        .sponsor__description{
            max-width: 140px;
        }

    }
}
