/* ==========================================================================
   Soundboard
   ========================================================================== */

$sbHeightLarge: 280px;
$sbHeightSmall: 180px;


/* ==========================================================================
   Soundboard - Header
   ========================================================================== */


.soundboard-header {
    color: #fff;
}
.soundboard-header__inner {
    width: 100%;
    position: relative;
    border-bottom: 2px solid get-color(brand, secondary);
}
.soundboard-header__title {
    font-family: font-stack(trim);
    text-transform: uppercase;
    background-color: $primaryColor;
    padding: 10px 15px;
    display: inline-block;
    margin-bottom: -2px;
    border-right: 30px solid #fff;
    font-size: 18px;

    @include mq(xsmall) {
        padding: 10px 20px;
    }
    @include mq(small) {
        font-size: 32px;
    }
}
.soundboard-header__filter-button {
    float: right;
    color: get-color(text);
    font-weight: bold;
    position: relative;
    z-index: z-scale(overlay);
    cursor: pointer;

    .filter-type{ display: none; }

    &:hover, &:active, &:focus {
        color: $primaryColor;
        text-decoration: none;
    }
    &.is-toggled{
        color: #fff;
        text-decoration: none;
        background-color: get-color(brand, secondary);
    }

    @include mq(xsmall) {
        .filter-type{ display: inline; }
    }
    @include mq(small) {
        margin-top: 15px;
    }
}
.soundboard-header__filters {
    background: get-color(brand, secondary);
    position: absolute;
    width: 200px;
    right: 0;
    padding: 50px 20px 20px 20px;
    font-size: 12px;
    margin-top: -5px;
    display: none;
    z-index: z-scale(overlay);

    &.is-toggled {
        display: block;
    }
    .input-group {
        border-bottom: 1px solid #444;
        padding: 0 0 20px 0;
    }
    .input-group__title {
        font-weight: bold;
        padding: 20px 0 10px 0;
    }
    .form-field {
        margin-bottom: .5em;
        font-family: font-stack(sans);
    }
}
.soundboard-header__filters-submit {
    margin-top: 10px;
}

/* ==========================================================================
   Soundboard - Grid
   ========================================================================== */

.soundboard-grid {
    @include clearfix();
    padding-top: 20px;
    .content-panel {
        margin-bottom: 1em;
    }
}
.soundboard-grid__inner{
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}
.soundboard-grid__article {
    width: 100%;
    padding: 10px;
}

.soundboard-grid__article--featured {
    .soundboard-item{
        border: 1px solid #ccc;
        padding: 10px;
    }
}
@include mq(small) {
    .soundboard-grid__article {
        width: 50%;
        float: left;
    }
    .soundboard-grid__article .soundboard-item {
        height: 100%;
    }
}

@include mq(medium) {

    .soundboard-grid__article {
        width: 33.3%;
    }
    .soundboard-grid__article--featured {
        width: 66.6%;
        .soundboard-item {
            @include clearfix();
            transition: box-shadow .15s ease-in-out;
            box-shadow: inset 0px 0px 0px 0px #ccc;
        }

        .soundboard-item:hover {
            box-shadow: inset 0px 0px 0px 2px #000;
        }
        .soundboard-item__media {
            width: 56.5%;
            float: left;
        }
        .soundboard-item__image{
            height: 100%;
            max-width: none;
            width: auto;
        }
        .soundboard-item__body{
            float: right;
            width: 43.5%;
        }
        .soundboard-item__title { font-size: 26px; }

    }

}
@include mq(large) {
    .soundboard-grid__article--featured {
        .soundboard-item__title {
            font-size: 30px;
            padding: 0 10px 0 20px;
        }
        .soundboard-item__image{
            width: 100%;
            max-width: none;
            height: auto;
        }
    }

}


/* ==========================================================================
   Soundboard - Latest Soundboards
   ========================================================================== */

.latest-soundboards{
    margin-bottom: 2em;
}
.latest-soundboards__title{
    font-family: font-stack(trim);
    font-size: 19px;
}
@include mq(small) {
    .latest-soundboards__title{
        font-size: 26px;
    }
}
@include mq(large) {
    .latest-soundboards .soundboard-item {
        height: $sbHeightLarge;
    }
}



/* ==========================================================================
   Soundboard - Soundboard Item
   ========================================================================== */

.soundboard-item{
    display: block;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding-left: 10px;
    padding-right: 10px;
    transition: border-color .25s ease-in-out;
    &:hover { border-color: #000; }
}

.soundboard-item__title {
    font-size: 18px;
    font-family: font-stack(domaine);
    font-style: italic;
    padding: 10px;
    text-transform: none;
    line-height: 1.2;
}
.soundboard-item__media{
    width: 100%;
    overflow: hidden;
    background-color: #000;
}
.soundboard-item__image{
    width: 100%;
    transition: transform 2s ease-in-out, opacity 2s ease-in-out;
    transition-delay: .25s;
    opacity: .75;
}

.soundboard-item:hover .soundboard-item__image {
    transform: scale(1.1, 1.1);
    opacity: 1;
}

@include mq(medium) {
    .soundboard-grid__article--featured .soundboard-item:hover .soundboard-item__image {
        transform: scale(1.1, 1.1);
    }
}



/* ==========================================================================
   Soundboard Article
   ========================================================================== */

.soundboard-article .primary-content {
    @include clearfix();
}

.soundboard-article__date-published {
    padding: .75em 0;
    border: 1px solid get-color(global, border);
    border-width: 1px 0;
    font-size: 14px;
}

.soundboard-article__details .share-tool{
    margin-top: 10px;
}


/* ==========================================================================
   Soundboard Article - Progress bar
   ========================================================================== */

.soundboard-article__reading-position{
    height: 5px;
    display: block;
    margin-top: -2em;
    margin-bottom: 2em;
}
.reading-position {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 5px;
    display: block;
    border: none;
    background-color: transparent;
    color: $primaryColor;

    &.is-fixed{
        position: fixed;
        top: 0;
        z-index: 1000;
    }
}

.reading-position::-webkit-progress-bar {
    background-color: transparent;
}
.reading-position::-webkit-progress-value {
  background-color: darken($primaryColor, 15%);
}
.reading-position::-moz-progress-bar {
  background-color: darken($primaryColor, 15%);
}

@if $theme == "thecorporation" {
    .reading-position::-webkit-progress-value {
      background-color: lighten($primaryColor, 15%);
    }
    .reading-position::-moz-progress-bar {
      background-color: lighten($primaryColor, 15%);
    }
}


/* ==========================================================================
   Soundboard Article - Header
   ========================================================================== */

.article-header {
    margin-bottom: 2em;
    color: #fff;
    width: 100%;
}


.article-header__inner {
    position: relative;
}


.article-header__top{
    background-color: #555;
}
.article-header__bottom{
    background-color: #777;
}
.article-header__branding {
    padding-top: $global-baseline;
}

.article-header__branding .branding{
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    line-height: 1rem;
    font-family: font-stack(trim);
}
.article-header__title{
    padding-top: $global-baseline/2;
    padding-bottom: $global-baseline;
    margin-bottom: 0;
    font-family: font-stack(domaine);
    font-size: 32px;
}
.article-header__sub-title{
    padding-top: $global-baseline;
    padding-bottom: $global-baseline;
    font-weight: 300;
    font-family: font-stack(body);
    text-transform: none;
    line-height: 1.2;
}
 @include mq($until: medium) {
    .article-header__title{ font-size: 22px; }
    .article-header__sub-title{ font-size: 18px; }
    .article-header__branding .branding{ font-size: 18px; }

 }
 @include mq(large) {

    .article-header__title {
        padding-left: 20%;
        padding-right: 20%;
        padding-top: $global-baseline * 2;
        padding-bottom: $global-baseline * 2;
    }

    .article-header__sub-title{
        padding-left: 20%;
        padding-right: 20%;
        padding-top: $global-baseline * 1.2;
        padding-bottom: $global-baseline * 1.2;
    }

    .article-header__branding {
        position: absolute;
        top: 0;
        bottom: 0;
    }

    .article-header__branding .branding {
        position: absolute;
        top: 50%;
        margin-top: -8px;
    }
}



/* ==========================================================================
   Soundboard Article - Main Image
   ========================================================================== */

   .article-image {
        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid get-color(global, border);
    }
    .article-image__media{
        @include mq( $until: medium) {
            margin-left: -$global-offset;
            margin-right: -$global-offset;
            padding-bottom: 63% !important;
        }
    }

   .article-image__caption {
        position: relative;
        background: none;
        font-size: 14px;
        color: get-color(text);
        padding-top: 20px;
        padding-bottom: 0;
   }
   .article-image__credit {
        font-size: 80%;
   }

/* ==========================================================================
   Soundboard Article - Author
   ========================================================================== */

    .article-author {
        @include clearfix();
        padding-bottom: 1em;
    }
    .article-author__avatar{
        float: left;
        margin-right: 12px;
        border-radius: 100px;
        width: 48px;
    }
    .article-author__name{
        font-size: 20px;
    }
    .article-author__twitter{
        display: inline-block;
    }
    .article-author__byline{
        clear: both;
        display: block;
        padding-top: 10px;
        font-size: 14px;
        margin-bottom: 0;
    }

/* ==========================================================================
   Soundboard Article - Primary Content
   ========================================================================== */

    .soundboard-article__content {
        padding-bottom: 2em;
    }

    @include mq(medium-major) {
        .soundboard-article__content {
            width: 70%;
            float: left;
            margin-right: 3%;
        }
        .soundboard-article__asides {
            width: 27%;
            float: left;
       }
    }

    @include mq(large) {
        .soundboard-article__details {
            float: left;
            width: 17%;
            margin-right: 3%;
        }
        .soundboard-article__content {
            width: 60%;
            float: left;
            margin-right: 3%;
        }
        .soundboard-article__asides {
            width: 17%;
            float: left;
       }
    }

/* ==========================================================================
   Soundboard Article - Recomended Articles
   ========================================================================== */

    .recommended-articles{
        .soundboard-item__title{
            min-height: 100px;
        }
        @include mq(small) {
            .soundboard-item__title{
                min-height: 150px;
            }
        }
        @include mq(medium) {
            .soundboard-item__title{
                font-size: 22px;
            }
        }
    }
