/* ==========================================================================
   Utilities: Visibility
   ========================================================================== */

/**
 * JS helpers
 */
.no-js .u-visible-js,
.supports-js .u-hidden-js {
    @include visually-hidden();
}
.supports-js .u-hidden-js {
    @include visually-hidden();
}

/**
 * Hide from browsers, but not screenreaders
 */
.visually-hidden,
.u-visually-hidden {
    @include visually-hidden();
}

/**
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
[hidden],
.u-hidden {
    display: none !important;
    visibility: hidden;
}

/**
 * Hide visually and from screenreaders, but maintain layout
 */
.u-invisible {
    visibility: hidden;
}

/**
 * Image Replacement
 */
.u-ir {
    display: block;
    text-indent: -999999px;
    overflow: hidden;
}
