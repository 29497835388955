/* ==========================================================================
   Tabs
   ========================================================================== */

.tabs,
.tabs__list {
    @include clearfix();
}
.tabs__list {
    @include reset-list();
}
.tabs__header {
    display: block;
    cursor: default;
    padding: 0 10px;
    padding-bottom: .5em;
    border-style: solid;
    border-color: get-color(neutral);
    border-width: 0 0 1px 0;
}
.tabs__body {
    width: 100%;
    margin-bottom: 1em;
    padding: 10px;
}

/**
 * Convert default stacked content into tabs
 * Only show tabs when JS is enabled
 */
@include mq(medium) {
    .supports-js {

        .tabs__list {
            position: relative;
        }
        .tabs__item {
            display: inline;
            font-size: 0; // Remove inter-cell whitespace that appears between `inline-block` child elements.
        }
        .tabs__header,
        .tabs__body {
            font-size: 16px; // Reset font size change made above
        }
        .tabs__header {
            cursor: pointer;
            display: inline-block;
            height: 2em;
            line-height: 2;
            margin: 0;
            color: get-color(neutral);
            border-width: 1px 1px 1px 0;
        }
        .tabs__item:first-child .tabs__header {
            border-left-width: 1px;
        }
        .tabs__item.is-active > .tabs__header,
        .tabs__header:hover {
            color: inherit;
            border-bottom-color: white;
        }
        .tabs__header:focus {
            outline: 2px solid get-color(global, focus);
        }
        .tabs__item.is-active .tabs__header {
            position: relative;
            z-index: 2;
        }
        .tabs__body {
            float: left;
            border: 1px solid get-color(neutral);
            position: relative;
            top: -1px;

            display: none;
            z-index: -1;
        }
        .tabs__item.is-active .tabs__body {
            display: block;
            z-index: 1;
        }
    }
}
