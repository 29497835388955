/* ==========================================================================
   Events
   ========================================================================== */

/* ==========================================================================
   Events: Event item
   ========================================================================== */

.event-item {
    display: block;
    padding-bottom: $global-baseline/2;
    margin-bottom: $global-baseline/2;
    border-bottom: 1px solid get-color(brand, secondary);
    @include mq(medium) {
        padding-bottom: $global-baseline;
        margin-bottom: $global-baseline;
    }
}

.event-item__date-description {
    font-size: 12px;
    display: block;
    font-family: font-stack(domaine);
    margin-top: 10px;
    text-transform: uppercase;

    @if $theme == "roythomsonhall" {
        font-family: font-stack(trim);
        -webkit-font-smoothing: auto;
        font-weight: normal;
    }
}
.date-seperator {
    display: inline-block;
    font-size: 0;
    vertical-align: middle;
    padding: 3px 5px 4px;
}
.date-seperator .icon-diamond {
    height: 8px;
    width: 8px;
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}
.event-item__title {
    margin: 5px 0;
    text-transform: uppercase;
    @include font-scale(display, 1);
    -webkit-font-smoothing: auto;
}
.event-item__link{
    display: block;
}
.event-item__prefix,
.event-item__suffix {
    font-size: 12px;
    font-family: font-stack(domaine);
    line-height: 1.3em;
    display: block;
}
.event-item__footer {
    margin-top: 10px;
    .btn{ margin-bottom: 10px; }

    @include mq($until: small) {
        clear: both;
        padding-top: 10px;
        text-align: center;
        border-top: 1px solid #eaeaea;

        .venue-icon { vertical-align: top; }
    }
    @include mq($until: xsmall) {
        .btn{ margin-left: 15px; }
    }
}
.event-item.has-image {
    @include clearfix();

    .event-item__media {
        width: 100px;
        float: right;
        padding-left: $global-baseline / 2;
        margin-bottom: 15px;

        @include mq(small) {
            width: 140px;
        }

        @include mq(medium) {
            width: 200px;
            padding-left: $global-baseline;
            margin-bottom: 0;
        }

        .no-js & {
            display: none;
        }
    }
    .event-item__content{
        @include mq(medium) {
            padding-right: 200px;
        }
    }

}

@include mq(medium) {
    .event-item__title {
        @include font-scale(display, 3);
    }
    .event-item__prefix,
    .event-item__suffix{
        font-size: 14px;
    }

    .event-item__date-description {
        font-size: 16px;
    }
}
@include mq(large) {
    .event-item__title {
        @include font-scale(display, 4);
    }
}

/* ==========================================================================
   Event: Availability Message
   ========================================================================== */

.availability-message {
    display: block;
    @include mq(small){ clear: both; }
    @include mq(medium) { clear: left; }
}
.availability-message__text {
    padding: 5px 10px;
    background-color: #eaeaea;
    color: #232323;
    text-align: center;
    font-size: 14px;
    display: block;
    @include mq(small){
        display: inline-block;
    }

    a{ border-bottom: 1px solid #333; }
    a:hover{ border-bottom-color: $primaryColor; }
}
.availability-message--header {
    clear: left;

    .availability-message__text {
        background-color: transparent;
        color: #fff;
        text-align: left;
        font-size: 16px;
        padding: 0;
        margin-bottom: 10px;

        a{ border-bottom: 1px solid #fff; }
        a:hover{ border-bottom-color: $primaryColor; }
    }
}

/* ==========================================================================
   Event: Performance Select
   ========================================================================== */

.performance-select {
    @include clearfix();
}
.performance-select__date {
    height: 27px;
    background: #eaeaea;
    color: #232323;

    @include mq($until:small) {
        margin-bottom: 10px;
    }
}

.book-status {
    color: #333;
    background-color: transparent;
    border: 1px solid #333;
    font-family: font-stack(trim);
    display: inline-block;
    vertical-align: bottom;
    line-height: 1.2em;
    margin-bottom: 10px;
    -webkit-font-smoothing: auto;
}

/* ==========================================================================
   Event: Venue Icons
   ========================================================================== */

.venue-icon {
    display: inline-block;
    height: 25px;
    text-transform: uppercase;
    background-color: get-color(brand, venue);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 1em;
    overflow: hidden;
    vertical-align: bottom;
    color: #fff;
    padding: 7px 4px 0;
    margin-bottom: 10px;
    border-bottom: none !important;
    text-align: center;
    text-decoration: none;
}

.venue-icon--MH {
    font-family: font-stack(trim);
    letter-spacing: 0.6px;
}
.venue-icon--RTH {
    font-family: font-stack(domaine);
}

/* ==========================================================================
   Events: Event grouping
   ========================================================================== */

.event-grouping {
    @include clearfix();
    margin-bottom: $global-baseline * 2;
}
.event-grouping__title {
    margin: 0 0 $global-baseline 0;
    padding-bottom: .75em;
    border-bottom: 1px solid get-color(brand, secondary);

    @include mq($until: xsmall) {
        font-size: 18px;
    }
}
.event-grouping__more-link {
    display: block;
}


/* ==========================================================================
   Events: What's On
   ========================================================================== */

    .whatson{
        background-color: get-color(neutral, light);
    }

    .whatson__inner{
        position: relative;
    }

    .whatson__header{
        @include clearfix();
    }
    .whatson__filter-button {
        float: right;
        margin-top: 23px;
        color: get-color(text);
        font-weight: bold;
        position: relative;
        z-index: z-scale(foreground);
        cursor: pointer;
        padding: 0;

        &:hover, &:active, &:focus {
            color: $primaryColor;
            text-decoration: none;
            background-color: transparent;
            border: none;
        }

        @include mq(medium) {
            display: none;
        }
    }
    .whatson__filter-button-text{
        @include mq($until: xsmall) {
            display: none;
        }
    }
    .whatson__title{
        padding: 1em 0 .75em 0;
        margin-bottom: 0;
        float: left;
        @include font-scale(display, 4);
        @include mq(medium) {
            @include font-scale(display, 6);
        }
    }
    .whatson-performances{
        margin-bottom: 0;
        padding-bottom: 1em;
        padding-top: 0;

        @include mq(medium) {
            padding-right: 3%;
            margin-right: 0;
            width: 70%;
        }

        .whatson-performances__group:first-child{
            .event-grouping__title{ margin-top: 0; }
        }
        .event-grouping__title{
            border-color: darken(#eaeaea, 10%);
            padding-bottom: .5em;
        }
        .event-item{
            border-color: #eaeaea;
        }
        .event-item:last-child{
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .event-item__date-description {
            text-transform: none;
        }
        .venue-icon {
            height: 25px;
            padding: 7px 4px 0;
        }
    }
    .whatson-performances__messages{
        clear: both;
        &.loading{
            padding: 20px 0 50px;
        }
        .loading{
            text-align: center;
            position: absolute;
            width: 100%;
            $_loading-a: darken($primaryColor, 10%);
            $_loading-b: $primaryColor;
            background-image:
                linear-gradient(
                    -45deg,
                    $_loading-a,
                    $_loading-a 25%,
                    $_loading-b 25%,
                    $_loading-b 50%,
                    $_loading-a 50%,
                    $_loading-a 75%,
                    $_loading-b 75%,
                    $_loading-b
                );
            animation: loading 1s linear infinite;
            background-size: 4em 4em;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
            font-family: font-stack(sans);
            font-size: 13px;
            padding: 2px 10px;
        }
        .applying-filters {
            position: relative;
        }
        @keyframes loading {
          to { background-position: 4em 0; }
        }
    }
    .whatson-performances__group{
        .event-grouping__title{
            @include font-scale(display, 2);

            @include mq(small) {
                @include font-scale(display, 3);
            }

            @include mq(medium) {
                @include font-scale(display, 4);
            }
        }
        .event-grouping__content{
            padding: 10px;
            background-color: #fff;
        }
    }

    .whatson-filters {
        width: 250px;
        padding: 25px;
        background-color: darken(get-color(neutral, light), 15%);
        color: #000;
        transition: right .5s ease;

        &.is-toggled {
            right: 0;
        }
        @include mq($until: medium) {
            position: fixed;
            right: -251px;
            height: 100%;
            overflow-y: scroll;
            top: 0;
            z-index: 100;
            padding: 15px;
        }
        .show-more {
            font-size: 11px;
            text-transform: uppercase;
            display: block;
            font-weight: bold;
            color: $primaryColor;
            cursor: pointer;
        }

        .show-more__text {
            display: inline-block;
            line-height: 20px;
        }
        .more-filters { display: none; }
        .more-filters.is-toggled { display: block; }
    }

    .whatson-filters__title {
        float: left;
        @include font-scale(display, 4);
    }

    .whatson-filters__reset {
        padding: 0;
        font-size: 14px;
    }

    .whatson-filters__form {
        position: relative;
        clear: both;
    }

    .whatson-filters__form:after {
        @include mq(medium) {
            content: "";
            position: absolute;
            width: 100%;
            height: 80px;
            bottom: 0;
            left: 0;
            z-index: z-scale(overlay);
            background: linear-gradient(to top, rgba(196, 196, 196, 1) 0%, rgba(196, 196, 196, 0.25) 100%);
        }
    }

    .whatson-filters__group {
        border-bottom: 1px solid darken(#eaeaea, 10%);
        margin-bottom: 1em;
        padding-bottom: 10px;
        font-size: 12px;

        &:last-child{ border-bottom: 0; }

        .form-field{
            margin-bottom: 5px;
        }

        .facet-clear {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 8px;
        }
    }
    .whatson-filters__form-footer{
        position: relative;
        z-index: 8;
    }
    .whatson-filters__close {
        cursor: pointer;
        float: right;
        font-size: 14px;
        color: #000;
        padding: 2% 3%;
        position: absolute;
        text-align: right;
        top: 13px;
        right: 3%;

        &, &:active, &:link, &:visited, &:focus {
            text-decoration: none;
        }

        &:hover{
            color: #000;
            text-decoration: none;
            background-color: darken(#eaeaea, 10%);
        }

        .o-icon {
            width: 16px;
            height: 16px;
        }
    }

    @include mq(medium) {
        .whatson-filters {
            width: 100%;
            margin-top: -50px;
        }
        .whatson-filters__form-body {
            max-height: 420px;
            overflow-y: scroll;
            padding-bottom: 80px;
        }
        .whatson-filters__close { display: none; }

        .whatson-wrapper {
            display: flex;
            justify-content: space-between;
        }

        .whatson-sidebar {
            display: flex;
            flex-direction: column;
            /* stylelint-disable */
            gap: 30px;
            /* stylelint-enable */
            width: 280px;
        }
    }


    .whatson-performances__sponsor {
        display: flex;
        align-items: flex-end;
        position: absolute;
        right: 0; top: 62px;
        @include mq(medium) {
            right: 33%; top: 58px;
            flex-direction: column;
        }
        @include mq(large) {
            flex-direction: row;
            top: 77px;
        }
    }
    .whatson-performances__sponsor__title {
        flex-shrink: 0;
        text-align: right;
        line-height: 1em;
        margin-right: 10px;
        margin-left: auto;
        font-size: 14px;
        display: none;
        @include mq(small-major) {
            display: block;
        }
        @include mq(medium) {
            margin: 0 0 5px;
        }
        @include mq(large) {
            margin: 0 10px 0 0;
        }
    }
    .whatson-performances__sponsor__image {
        background-image: url(../images/logo-calendar-sponsor.png);
        background-repeat: no-repeat;
        background-position: center right;
        background-size: contain;
        width: 90px;
        @include mq(medium) {
            width: 130px;
            height: 30px;
        }
    }

    .whatson-sponsor {
        position: sticky;
        top: 30px;
        padding-bottom: 48px;

        @include mq($until: medium) {
            display: none;
        }
    }

/* ==========================================================================
   Event: Virtual Video Event
   ========================================================================== */

.online-event {
    display: flex;
    flex-direction: column;

    @include mq(large) {
        flex-direction: row;
    }
}

.online-event__video {
    width: 100%;

    @include mq(large) {
        width: 65%;
    }
}

.online-event__video-inner {
    padding-bottom: 56.25%;
    position: relative;
}

.online-event__chat {
    width: 100%;
    height: 100%;

    @include mq(large) {
        width: 35%;
    }
}

.online-event__chat-inner {
    position: relative;
    padding-bottom: 500px;

    @include mq(large) {
        padding-bottom: 104.5%;
    }
}

.online-event iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0 !important;
}
