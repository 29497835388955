/* ==========================================================================
   Feature Panels
   ========================================================================== */

.feature-panel {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 2em;

    @include mq( large) {
        color: get-color(text, reversed);
        background-color: black;
        margin-bottom: 0;
    }
}
.feature-panel:hover {
    @include mq( large) {
        .feature-panel__body {
            bottom: 0;
        }
    }
}
.feature-panel__media {
    width: 100%;
}
.feature-panel__body {
    @include mq( large) {
        position: absolute;
        bottom: -100%;
        background-color: $primaryColor;
        height: 100%;
        padding: $global-baseline * 2;
        transition: bottom ease .5s;
        width: 100%;
    }
}
.feature-panel__title {
    margin: 10px 0;
}
@include mq($until: large) {
    .feature-panel__cto {
        display: block;
    }
}

.feature-panel--peek{
    @include mq( large) {
        .feature-panel__body {
            bottom: 0;
            height: auto;
            padding: 16px 32px;
        }
        .feature-panel__details {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transition: opacity ease .5s;
        }
    }
}

.feature-panel--peek:hover{
    @include mq( large) {
        .feature-panel__body {
            height: 100%;
            padding: 32px;
        }
        .feature-panel__details {
            max-height: 300px;
            opacity: 1;
        }
    }
}

/**
 * Collection of feature panels
 */
.feature-panels {
    padding: 2em 0;
    border-top: 1px solid get-color(global, border);
}
