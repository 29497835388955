/* ==========================================================================
   Promo Codes
   ========================================================================== */

.active-promocode {
    position: relative;
    color: #fff;
    background-color: darken($primaryColor, 20%);
    font-family: sans-serif;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 0;

    max-height: 0;
    transition: max-height 500ms;
    overflow: hidden;

    &.is-visible {
        max-height: 80px;
    }
}
.active-promocode__inner {
    @include clearfix();
    padding: .5em;
    padding: 0.75vw;
    text-align: center;
}
.active-promocode__message,
.active-promocode__actions  {
    display: inline-block;
    vertical-align: middle;
}
.active-promocode__message {
    margin-right: 10px;
    a { text-decoration: underline; }
}

.promo-code {
    width: 100%;
    max-width: 720px;
    background-color: #fff;
    margin: 3em auto;
}

.promo-code__container {
    text-align: center;
    padding: 1em;
}

.promo-code__image{
    margin-bottom: 1em;
    p{
        padding: 0;
        margin: 0;
    }
    img{
        width: 100%;
        float: none;
        height: auto;
    }
    br{
        display: none;
    }
}
.promo-code__title{
    p{ margin-bottom: 0; }
    b{ font-weight: 500; }
}

.promo-code__description, .promo-code__description p{
    text-align: center !important;
    font-size: 14px !important;
}

.promo-code__form{
    margin: 1em 0;
}

.promo-code__input{
    height: 38px;
    line-height: 38px;
    width: 200px;
    font-size: 20px;
    vertical-align: top;
}
