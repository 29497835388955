/* ==========================================================================
   Chat Integration
   ========================================================================== */

.chat-integration{
    bottom: 15px;
    right: 18px;
    position: fixed;
    z-index: 99999;

    img {
        flex-shrink: 0;
        width: 2em;
        position: relative;
        z-index: 2;
    }
    a {
        display: flex;
        align-items: center;

        &:after {
            content: 'Live Chat';
            color: white;
            display: inline-block;
            font-family: font-stack(sans);
            font-size: 12px;
            line-height: 1em;
            white-space: pre;
            background-color: #000;
            padding: 6px 10px 6px 17px;
            margin-left: -10px;
            z-index: 1;
        }
    }

    @include mq(small) {
        img {
            width: 3em;
        }

        a:after {
            padding: 10px 10px 10px 17px;
        }
    }
    @include mq(medium-major) {
        bottom: 120px;
        left: 20px;
        right: initial;

        a:after {
            font-size: 16px;
        }
    }

    @include mq(xlarge) {
        left: 30px;
    }

    body.show-production-header & {
        bottom: 5em;

        @include mq(medium) {
            bottom: 90px;
        }
        @include mq(medium-major) {
            bottom: 120px;
        }
    }
}
