/* ==========================================================================
   Object: Embeds
   ========================================================================== */

.o-embed {
    height: 0;
    position: relative;
/*  Default 3:2 aspect-ratio */
    padding-bottom: percentage(2/3);
}
.o-embed--video {
/*  16:9 aspect-ratio for video */
    padding-bottom: percentage(9/16);
}
.o-embed__iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
}
