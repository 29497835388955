/* ==========================================================================
   Page
   ========================================================================== */

.global-container {
    position: relative;

    @include mq(medium-major) {
        margin-left: $global-sidebar-width;
    }

    @include mq(xlarge) {
        margin-left: $global-sidebar-width--large;
    }
}

/* ==========================================================================
   Page: Page Header
   ========================================================================== */

.page-header {
    color: white;
    position: relative;

    &.has-image {
        background-color: get-color(neutral);
    }
}
.page-header__inner {
    color: black;
}
.page-header.has-image .page-header__inner {
    background-image:
    linear-gradient(
        to top,
        rgba(0, 0, 0, 0.5),
        transparent
    );
    bottom: 0; left: 0;
    color: white;
    position: absolute;
    width: 100%;
    z-index: z-scale(foreground);
}
.page-header__title {
    padding: 25px 0 15px 0;
    margin: 0;
    @include font-scale(display, 4);

    @include mq(small) {
        @include font-scale(display, 6);
    }
    @include mq(medium) {
        @include font-scale(display, 7);
    }
}
.page-header.has-image {
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
}
.page-header__caption {
    font-family: font-stack(sans);
    position: absolute;
    z-index: z-scale(foreground);
}
.page-header.has-image {
    position: relative;
}
.page-header__image > img {
    width: 100%;
}

/* ==========================================================================
   Page: Page Image
   ========================================================================== */

.page-image {
    position: relative;
    margin-bottom: 0;
}
.page-image__image {
    width: 100%;
}
.page-image__caption {
    font-family: font-stack(sans);
    position: absolute;
    z-index: z-scale(foreground);
}

/* ==========================================================================
   Page: Content Panel
   ========================================================================== */

.content-panel {
    margin-bottom: 2em;
}

/* ==========================================================================
   Page: Section Header
   ========================================================================== */

.section-header {
    @include clearfix();
    border-bottom: 1px solid get-color(neutral);
    margin-bottom: 1em;
    padding-bottom: 0.5em;
}
.section-header__title {
    margin: 0 0 .25em 0;
}
@include mq(small) {
    .section-header__title {
        float: left;
        margin: 0;
    }
    .section-header__actions {
        float: right;
        width: auto;
    }
}

/* ==========================================================================
   Page: Content
   ========================================================================== */

.page-title{
    @include font-scale(display, 4);
    @include mq(xsmall) {
        @include font-scale(display, 5);
    }
    @include mq(small) {
        @include font-scale(display, 6);
    }
}

.page-summary {
    padding: $global-baseline 0;

   @include mq(medium-major) {
        width: 70%;
   }

   @include mq(large) {
        width: 60%;
   }
}




.primary-content.full-width {
    padding-top: 30px;
    padding-bottom: 30px;
}
