/* ==========================================================================
   Objects: Blocks
   ========================================================================== */

.o-slats {
   @include reset-list();
   margin-bottom: 0;
}
.o-slats__item {
   display: block;
   font-weight: normal;
   padding: .5em 0;
   border-bottom: 1px solid get-color(neutral);
   &:last-of-type { border-bottom: none; }
}

.o-well {
    @include well();
}

.o-blockquote {
    @include blockquote();
}
.o-blockquote p{
    font-family: font-stack(domaine);
    font-size: 120%;
}
.o-blockquote__footer {
    font-size: 80%;
    line-height: 1.3;
    padding: 0.5em 0;
    clear: left;
}
