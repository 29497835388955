/* ==========================================================================
   Objects: Text
   ========================================================================== */

.o-lead,
.o-lede {
    font-size: 120%;
    line-height: 1.333;
    font-weight: 300;
    @include mq(medium) {
    	font-size: 150%;
    }
}
