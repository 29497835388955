// //  -------------------------------------------------------- //
// //  Hosted Fonts
// //  -------------------------------------------------------- //

// /**
//  * Trim Poster
//  */
// @font-face {
//     font-family: 'Trim Poster';
//     src: url('../fonts/TrimPosterWeb-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// /**
//  * Domaine
//  */
// @font-face {
//     font-family: 'Domaine';
//     src:
//         url('../fonts/DomaineDisplayWeb-Extrabold.woff2') format('woff2'),
//         url('../fonts/DomaineDisplayWeb-Extrabold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Domaine';
//     src:
//         url('../fonts/DomaineDisplayWeb-Medium.woff2') format('woff2'),
//         url('../fonts/DomaineDisplayWeb-Medium.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Domaine';
//     src:
//         url('../fonts/DomaineDisplayWeb-MediumItalic.woff2') format('woff2'),
//         url('../fonts/DomaineDisplayWeb-MediumItalic.woff') format('woff');
//     font-weight: normal;
//     font-style: italic;
// }



//  -------------------------------------------------------- //
//  Hosted Fonts
//  -------------------------------------------------------- //

/**
 * Trim Poster
 */
@font-face {
    font-family: 'Trim Poster';
    src: url('https://d3vc61m0ib870y.cloudfront.net/latest/fonts/TrimPosterWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/**
 * Domaine
 */
@font-face {
    font-family: 'Domaine';
    src:
        url('https://d3vc61m0ib870y.cloudfront.net/latest/fonts/DomaineDisplayWeb-Extrabold.woff2') format('woff2'),
        url('https://d3vc61m0ib870y.cloudfront.net/latest/fonts/DomaineDisplayWeb-Extrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Domaine';
    src:
        url('https://d3vc61m0ib870y.cloudfront.net/latest/fonts/DomaineDisplayWeb-Medium.woff2') format('woff2'),
        url('https://d3vc61m0ib870y.cloudfront.net/latest/fonts/DomaineDisplayWeb-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Domaine';
    src:
        url('https://d3vc61m0ib870y.cloudfront.net/latest/fonts/DomaineDisplayWeb-MediumItalic.woff2') format('woff2'),
        url('https://d3vc61m0ib870y.cloudfront.net/latest/fonts/DomaineDisplayWeb-MediumItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

