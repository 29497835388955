/* ==========================================================================
   Global Header
   ========================================================================== */

.global-header {
    color: white;
    font-family: font-stack(sans);
    @include mq(medium-major) {
        // header height. It's a magic number
        height: 42px;
    }
}
.global-header__inner{
    @include clearfix();
    box-sizing: border-box;
    background-color: $primaryColor;
    padding: 10px 0;

    @include mq(medium-major) {
        position: fixed;
        padding: 12px 0;
        width: calc(100% - 180px);
        z-index: z-scale(navigation);
        top: 0;
        opacity: 1;
        transition: opacity .5s ease;

        &.is-pinned{
            opacity: 1;
        }
        &.is-unpinned{
            opacity: 0;
        }
    }
    @include mq(xlarge) {
        width: calc(100% - 200px);
    }
}

/* ==========================================================================
   Global Navigation
   ========================================================================== */

.global-navigation {
    @include reset-list();
    overflow: auto;
    margin-bottom: 0;
    display: none;

    @include mq(medium-major) {
        display: block;
        float: left;
    }
}
.global-navigation__item {
    display: block;
    list-style: none;
    margin-left: 0;
    font-size: 12px;

    @include mq(medium-major) {
        float: left;
    }
}
.global-navigation__link,
.global-navigation__link:visited {
    display: block;
    color: inherit;
    margin-right: 2em;
    font-weight: normal;
    border-bottom: 1px solid $primaryColor;
}
.global-navigation__item:last-child {
    .global-navigation__link { padding-right: 0; }
}
@include mq(medium-major) {
    .global-navigation__item.current .global-navigation__link {
        font-weight: bold;

        &:hover {
            border-bottom-color: $primaryColor;
        }
    }
    .global-navigation__link:hover {
        border-bottom: 1px solid #fff;
    }
}
.global-header__navigation-switch {
    display: block;
    float: left;
    font-size: 12px;
    font-family: font-stack(sans);
    text-decoration: none;
    color: get-color(text, reverse);
    padding: 0;

    @include mq(medium-major) {
        display: none;
    }

    .o-icon {
        position: relative;
        top: 0;
        width: 11px;
        height: 11px;
    }
}
.global-navigation.is-toggled {
    @include mq($until: medium-major) {
        display: block;
        clear: both;
        padding: 30px 0 20px 0;

        .global-navigation__item {
            text-align: center;
            text-transform: uppercase;
            padding: 5px 0;
            border-top: 1px solid darken($primaryColor, 10%);

            @if $theme == "thecorporation" {
                border-color: rgba(0,0,0,0.5);
            }

            &:first-child{
                border-top: none;
            }
        }
        .global-navigation__link,
        .global-navigation__link:visited{
            padding-right: 0;
        }

    }
}

/* ==========================================================================
   Global Toolbar
   ========================================================================== */

.global-toolbar {
    @include reset-list();
    float: right;
    font-size: 12px;
    position: relative;
}
.global-toolbar__item {
    display: block;
    float: left;
    list-style: none;
    margin-left: 0;
    margin-right: 10px;
}
.global-toolbar__item:last-child {
    margin-right: 0;
}
.global-toolbar__link,
.global-toolbar__link:visited {
    display: block;
    color: inherit;
    padding-right: 2em;
    font-weight: normal;
}
.global-toolbar__cart {
    position: relative;
    padding-right: 25px;

    .o-badge {
        position: absolute;
        top: -4px;
        right: 0;
    }
}
.global-toolbar__navigation {
    position: relative;
}
.global-toolbar__navigation__popover {
    position: absolute;
    top: 100%;
    z-index: -1;
    right: -5px;
    margin-top: 15px;
    display: none;

    &.is-active {
        z-index: z-scale(modal);
        transform: translateY(0px);
        opacity: 1;
        display: block;
    }
}

/* ==========================================================================
   Popover Navigation
   ========================================================================== */

.popover-navigation {
    background: white;
    font-size: 13px;
    line-height: 1.1;
        box-shadow: 0 -1px 10px rgba(0,0,0,.25);
    border-radius: 2px;
    padding: 5px 0;

    &:before {
        content: ' ';
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        position: absolute;
        top: -10px;
        right: 10px;
    }
}
.popover-navigation__item {
    display: block;
    border-bottom: 1px dotted get-color(neutral, light);
    &:last-child {
        border-bottom: none;
    }
}
.global-toolbar__item:last-child {
    .global-toolbar__link { padding-right: 0; }
}
.popover-navigation__link {
    border: 0;
    outline: none;
    background: transparent;
    white-space: nowrap;
    display: block;
    text-align: left;
    width: 100%;
    padding: 10px;
    color: #666;

    &:hover {
        color: black;
    }
}
