/* ==========================================================================
   Object: Buttons
   ========================================================================== */

.btn,
.inline-button,
[type="submit"],
[type="button"] {
    cursor: pointer;
    display: inline-block;
    user-select: none;
    -webkit-appearance: none;

    font: inherit;
    font-size: 15px;
    font-weight: normal;
    font-family: font-stack(trim);
    letter-spacing: 0.6px;

    margin: 0;
    line-height: 1.1;
    padding: 10px 1em;
    position: relative;

    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;

    color: #fff;
    background-color: get-color(brand, secondary);
    border: 1px solid get-color(brand, secondary);
    border-radius: 0;

    &,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &:hover,
    &:active {
        background-color: darken(get-color(brand, secondary), 10%);
        border-color: darken(get-color(brand, secondary), 10%);
    }

    &:focus,
    &:focus:active {
        outline: 2px solid get-color(brand, secondary);
        background-color: darken(get-color(brand, secondary), 10%);
    }
}
.btn[disabled],
.btn[disabled]:hover,
.btn.is-disabled,
.btn.is-disabled:hover,
[type="submit"][disabled],
[type="submit"][disabled]:hover {
    cursor: not-allowed;
    border-color: get-color(neutral, dark);
    background-color: get-color(neutral, dark);
}
.btn[disabled]:hover,
.btn.is-disabled:hover,
[type="submit"][disabled]:hover{
    background-color: get-color(neutral, dark);
}

.btn--primary,
[type="submit"] {
    background-color: $primaryColor;
    border: 1px solid $primaryColor;

    &:hover,
    &:active {
        background-color: darken($primaryColor, 10%);
        border-color: darken($primaryColor, 10%);
    }
}

.btn--slim {
    font-size: 13px;
    padding: 5px 1em;
    height: 25px;
}

.btn--link,
[type="reset"] {
    color: get-color(text);
    background-color: transparent;
    margin: 0;
    text-decoration: none;
    border: none;
    border-radius: 0;
    cursor: inherit;

    &:active,
    &:focus {
        outline: none;
    }

    &:hover,
    &:focus,
    &:active {
        color: get-color(text, dark);
        background-color: transparent;
        text-decoration: underline;
    }
}

.btn--loading {
    $_loading-a: rgb(50, 50, 50);
    $_loading-b: rgb(100, 100, 100);

    background-image:
        linear-gradient(
            -45deg,
            $_loading-a,
            $_loading-a 25%,
            $_loading-b 25%,
            $_loading-b 50%,
            $_loading-a 50%,
            $_loading-a 75%,
            $_loading-b 75%,
            $_loading-b
        );
    animation: loadingButton 1s linear infinite;
    background-size: 4em 4em;
    color: white;
}
@keyframes loadingButton {
  to { background-position: 4em 0; }
}

.btn--success {
    color: get-color(button, success);
    background-color: lighten(get-color(button, success), 50%);
    border-color: lighten(get-color(button, success), 35%);
}
.btn--warning {
    color: white;
    background-color: get-color(button, warning);
    border-color: darken(get-color(button, warning), 10%);
}

/* ==========================================================================
   Object: Button Set
   ========================================================================== */

.button-set {
    @include clearfix();

    .btn {
        display: block;
        float: left;
    }
    .btn:first-child {
        border-radius: 100px 0 0 100px;
    }
    .btn:last-child {
        border-left: none;
        border-radius: 0 100px 100px 0;
    }
}

/* ==========================================================================
   Book Buttons
   ========================================================================== */

.book-button {
    @include clearfix();
    color: $primaryColor;
    background-color: transparent;
    border: 2px solid $primaryColor;
    position: relative;
    padding: 0;
    margin: 0;

    cursor: pointer;
    display: inline-block;
    user-select: none;
    -webkit-appearance: none;

    font: inherit;
    font-size: 15px;
    line-height: 1.1;
    font-weight: bold;
    font-family: font-stack(trim);
    letter-spacing: 0.4px;

    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
}
.book-button:hover {
    color: white;
    background-color: $primaryColor;
    border-color: $primaryColor;
    text-decoration: none;
}
.book-button[disabled] {
    cursor: not-allowed;
    color: get-color(neutral, dark);
    background-color: transparent;
    border-color: get-color(neutral, dark);

    .book-button__icon {
        background-color: get-color(neutral, dark);
    }
}
.book-button:not(.book-button--primary)[disabled]:hover {
    color: white;
    background-color: get-color(neutral, dark);
    border-color: get-color(neutral, dark);
}
.book-button__icon,
.book-button__text {
    display: block;
    float: left;
}
.book-button__icon {
    color: white;
    background-color: $primaryColor;
    padding: 0 3px 0 0;

    .o-icon {
        width: 23px;
        height: 23px;
    }
}
.book-button__text {
    text-transform: uppercase;
    text-align: center;
    line-height: 23px;
    padding: 0 1em;
    font-weight: normal;
}

/**
 * Book Button: Primary
 */
.book-button--primary {
    color: white;
    border-color: white;
    border: 4px solid white;
    font-size: 16px;

    .book-button__text {
        line-height: 34px;
    }

    .book-button__icon {
        color: get-color(brand, secondary);
        background-color: white;

        .o-icon {
            width: 36px;
            height: 36px;
        }
    }

    &:hover {
        color: get-color(text);
        background-color: white;
        border-color: white;
    }

    &[disabled]:hover {
        color: #201d1e;
        background-color: get-color(neutral, dark);
        border-color: get-color(neutral, dark);

        .book-button__icon {
            background-color: get-color(neutral, dark);
        }
    }
}

.booking-container {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
}

/* ==========================================================================
   LAMH Button
   ========================================================================== */

.btn--lamh {
    background-color: get-color(lamh, secondary);
    border: 1px solid get-color(lamh, secondary);
    color: get-color(lamh, primary);

    &:hover,
    &:active {
        background-color: darken(get-color(lamh, secondary), 10%);
        border-color: darken(get-color(lamh, secondary), 10%);
    }
}

/* ==========================================================================
   Watch Now Button
   ========================================================================== */

.watch-now {
    @include clearfix();
    border: 2px solid get-color(lamh, secondary);
    background-color: transparent;
    color: #fff;
    padding: 0;
}
.watch-now:hover{
    background-color: get-color(lamh, secondary);
    border-color: get-color(lamh, secondary);
    color: get-color(lamh, primary);
}
.watch-now__icon {
    background-color: get-color(lamh, secondary);
    display: inline-block;
    float: left;
    padding: 3px 4px 4px 2px;
    color: get-color(lamh, primary);
}
.watch-now__icon .o-icon{
    width: 16px;
    height: 16px;
}
.watch-now__text {
    text-transform: uppercase;
    text-align: center;
    line-height: 22px;
    padding: 0 1em;
    display: inline-block;
    font-weight: normal;
    float: right;
}
