/* ==========================================================================
   Grid
   ========================================================================== */

@mixin grid-item() {
    float: left;
    margin-left: $global-gutter / 2;
    padding-left: $global-gutter / 2;
    margin-bottom: $global-gutter;
}

.o-grid {
    @include clearfix();
    padding-left: 0;
}
.o-grid,
.o-grid__item {
    list-style: none;
    margin: 0;
}
.o-grid__item {
    border-color: transparent;
    margin-bottom: $global-gutter;
}

/**
 * Drop bottom margins if we know we only
 * have a single row of grid items.
 */
.o-grid--single-row {
    @include mq(large) {
        .o-grid__item {
            margin-bottom: 0 !important;
        }
    }
}

/**
 * Even numbers
 */
.o-grid--2up,
.o-grid--4up {
    @include mq(small) {
        width: 100% + $global-gutter;
        margin-left: -$global-gutter;
    }
    .o-grid__item {
        @include mq(small) {
            @include grid-item();
            width: 50% - ($global-gutter / 2);
        }
        @include mq(medium) {
            border-style: solid;
            border-width: 0 0 0 1px;
        }
    }
}
.o-grid--2up {
    .o-grid__item {
        @include mq(medium) {
            &:nth-of-type(2n+1) {
                clear: left;
                border-left: none;
            }
        }
    }
}
.o-grid--4up {
    .o-grid__item {
        @include mq(medium) {
            width: 25% - ($global-gutter / 2);
            &:nth-of-type(2n+1) {
                clear: none;
            }
            &:nth-of-type(4n+1) {
                clear: left;
                border-left: none;
            }
        }
    }
}

/**
 * Odd numbers
 */
@include mq(medium) {
    .o-grid--3up {
        width: 100% + $global-gutter;
        margin-left: -$global-gutter;

        .o-grid__item {
            @include grid-item();
            width: 33.3% - ($global-gutter / 2);
            &:nth-of-type(2n+1) {
                clear: none;
            }
            &:nth-of-type(3n+1) {
                clear: left;
                border-left: none;
            }
        }
    }
}

/* ==========================================================================
   Grid: 2col
   ========================================================================== */

.o-2col {
    @include clearfix();
}
.o-2col__primary,
.o-2col__secondary {
    padding: ($global-baseline * 2) 0 0;
    margin-bottom: $global-baseline;
}
@include mq(medium) {
    .o-2col__primary {
        float: left;
        width: 62%;
        margin-left: 0;
        margin-right: 3%;
    }
    .o-2col__secondary {
        float: right;
        width: 35%;
        max-width: 280px;
    }
}
@include mq(xlarge) {
    .o-2col__primary {
        float: left;
        width: 67%;
        margin-left: 0;
        margin-right: 3%;
    }
    .o-2col__secondary {
        float: right;
        width: 30%;
    }
}

.o-2col--reversed {
    @include mq(medium) {
        .o-2col__primary {
            float: right;
            width: 67%;
            margin-left: 3%;
            margin-right: 0;
        }
        .o-2col__secondary {
            float: left;
            width: 30%;
        }
    }
}
