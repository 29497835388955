/* ==========================================================================
   Tables
   ========================================================================== */

table {

    box-sizing: border-box;
    width: 100%;
    padding: 0;
    border-collapse: collapse;
    margin: 0 0 1em 0;

    thead, th {
        font-weight: bold;
        vertical-align: middle;
    }
    thead {
        border-bottom: 3px solid get-color(neutral);
    }
    tr {
        border-bottom: 1px solid get-color(neutral);
    }
    tr:last-of-type {
        border-bottom: none;
    }

    th, td {
        padding: .5em;
        padding-left: 0;
        text-align: left;
    }
    td {
        vertical-align: top;
    }

    caption {
        caption-side: bottom;
        color: get-color(neutral);
        font-size: 80%;
        text-align: left;
        margin: 1em 0;
    }
}

.table-container {
    width: 100%;
    overflow-y: auto;
    _overflow: auto;
    margin: 0 0 1em;
}
