/* ==========================================================================
   Carousels - Slick
   ========================================================================== */

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Dots */

.slick-dots {
    position: absolute;
    bottom: -45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 15px;
        width: 15px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            background: #fff;
            border: none;
            display: block;
            height: 15px;
            width: 15px;
            outline: none;
            padding: 5px;
            cursor: pointer;
            text-indent: -999999px;
            border-radius: 20px;
            opacity: .65;

            &:hover{
                background: #fff;
                opacity: 1;
            }
        }
        &.slick-active button{
            opacity: 1;
        }
    }
}




