/* ==========================================================================
   Sponsors
   ========================================================================== */

.sponsor{
    border-top: 1px solid get-color(global, border);
    padding-top: 1em;
    margin-top: 1em;
    display: block;
}
.sponsor__description{
    padding: 0 5px;
    font-style: italic;
}
