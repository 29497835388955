/* ==========================================================================
   Venue
   ========================================================================== */

.venue-homepage{
    @include clearfix();
}
.venue-homepage__primary, .venue-homepage__secondary{
    margin-bottom: $global-baseline;
}
.venue-homepage__primary{
    padding-top: ($global-baseline * 2);
    width: 100%;
}
.venue-homepage__secondary{ padding-top: $global-baseline; }

.venue-homepage__primary .event-grouping__title{
    @include font-scale(display, 3);

    @include mq(small) {
        @include font-scale(display, 5);
    }
}

@include mq(medium) {
    .venue-homepage__primary, .venue-homepage__secondary{
        padding: ($global-baseline * 3) 0 0;
    }
    .venue-homepage__primary:has(+ .venue-homepage__secondary){
        float: left;
        width: 62%;
        margin-left: 0;
        margin-right: 3%;
    }
    .venue-homepage__secondary{
        float: right;
        width: 35%;
    }
}
@include mq(xlarge) {
    .venue-homepage__primary:has(+ .venue-homepage__secondary){
        width: 67%;
    }
    .venue-homepage__secondary{ width: 30%; }
}



/* ==========================================================================
  Venue Hero
  ========================================================================== */

.venue-hero {
   box-shadow: 0 -12px 50px rgba(0,0,0,0.3) inset;
   background-position: top center;
   background-size: contain;
   overflow: hidden;
   position: relative;
   width: 100%;
   padding: 20% 10px;
   display: flex;
   justify-content: center;
   align-items: center;

   &--feature {
        padding: 60% 0 0;
   }

    @include mq(small) {
        padding: 10% 0;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
        min-height: 50vh;
    }
    @include mq(medium) {
        min-height: 70vh;
    }

   .venue-hero__caption {
       background-color: rgba(0, 0, 0, 0.6);
       border-radius: 5px;
       bottom: 3%;
       color: #fff;
       display: none;
       max-width: 60%;
       opacity: 0;
       padding: 5px 10px;
       position: absolute;
       right: 5%;
       transition: opacity 0.5s;
       @include mq(small) {
           display: block;
       }
   }
   &:hover {
       .venue-hero__caption {
           opacity: 1;
       }
   }
}

.venue-hero__body {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    text-align: center;
    background-color: #000;

    @include mq(small) {
        width: auto;
        padding: 20px 0;
        background-color: transparent;
    }
}

.venue-hero__body .btn {
    width: 35%;
    margin: 0;
}

.venue-hero__title {
    @include font-scale(display, 6);
    color: $primaryColor;
    border-bottom: 3px solid $primaryColor;
    margin-bottom: 20px;
    display: inline-block;
    @include mq(small) {
        @include font-scale(display, 7);
    }
    @include mq(medium) {
        @include font-scale(display, 8);
    }
}

.venue-hero__description {
    color: #fff;
    font-family: font-stack(display);
    @include font-scale(display, 2);
    @include mq(small) {
        @include font-scale(display, 5);
    }
}
.venue-hero__description p {
    line-height: 1.1em;
}

.venue-hero__search {
    position: relative;
    z-index: z-scale(foreground) + 1;
    text-align: center;
    width: 100%;

    .global-search {
        margin-bottom: 0;
    }

    .twitter-typeahead {
        box-sizing: content-box;
        width: 70%;
        max-width: 350px;
        position: static !important;
    }

    .global-search__input,
    .global-search__submit {
        display: inline-block;
    }

    input[type=search].global-search__input {
        width: 100%;
    }
    .global-search__submit {
        margin-left: -5px;
    }

    @include mq($until:xsmall) {
        input[type=search].global-search__input {
            border-radius: 3px;
        }
    }

    @include mq(medium) {
        .twitter-typeahead {
            width: 90%;
        }
        input[type=search].global-search__input {
            box-shadow: 0 0 10px rgba(0,0,0,0.4), 75px 0 10px rgba(0,0,0,0.4);
        }

    }
}

.venue-hero__donate {
    text-align: center;
}

.venue-hero__divide {
    color: #fff;
    font-weight: bold;
    position: relative;
    display: block;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 10px 0;

    &:before {
        content: "";
        width: 100px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        margin-left: -120px;
        top: 22px;
    }
    &:after {
        content: "";
        width: 100px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        margin-left: 20px;
        top: 22px;
    }

    @include mq(medium) {
        &:before {
            width: 200px;
            margin-left: -220px;
        }
        &:after {
            width: 200px;
        }
    }
}

.venue-hero__video {
    display: none;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: z-scale(foreground);
    margin-top: -10%;

    @include mq(medium) {
        display: block;
    }

    @media (min-height: 750px) {
        margin-top: 0;
    }

    .venue-hero__video-element {
        opacity: 0;
        transition: opacity 1s ease;
    }

    &.has-video .venue-hero__video-element {
        opacity: 1;
    }

    &.is-stopped .venue-hero__video-element {
        opacity: 0;
        transition: opacity 0 ease;
    }
}
.venue-hero__video-element {
    width: 100%;
    height: auto;
}
.venue-hero__video-controls {
    position: absolute;
    left: 20px;
    bottom: -20%;
    z-index: z-scale(foreground);

    @media (min-height: 750px) {
        bottom: 20px;
    }
}
.venue-hero__video-button,
.venue-hero__video-button:focus,
.venue-hero__video-button:hover {
    border: 0 none;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    direction: ltr;
    text-indent: -99999px;
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KPGc+Cgk8bGluZSBzdHJva2Utd2lkdGg9IjEiIHgxPSIiIHkxPSIiIHgyPSIiIHkyPSIiIHN0cm9rZT0iIzQ0OUZEQiIgb3BhY2l0eT0iIj48L2xpbmU+CjwvZz4KCTxwYXRoIGQ9Ik0xLjc1IDEuNzVoNC4zNzV2MTAuNWgtNC4zNzV6TTcuODc1IDEuNzVoNC4zNzV2MTAuNWgtNC4zNzV6IiBmaWxsPSIjZmZmZmZmIj48L3BhdGg+Cjwvc3ZnPgo=);
    cursor: pointer;
    outline: none;
}
.venue-hero__video-button.is-paused,
.venue-hero__video-button.is-paused:focus,
.venue-hero__video-button.is-paused:hover {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KPGc+Cgk8bGluZSBzdHJva2Utd2lkdGg9IjEiIHgxPSIiIHkxPSIiIHgyPSIiIHkyPSIiIHN0cm9rZT0iIzQ0OUZEQiIgb3BhY2l0eT0iIj48L2xpbmU+CjwvZz4KCTxwYXRoIGQ9Ik0yLjYyNSAxLjc1bDguNzUgNS4yNS04Ljc1IDUuMjV6IiBmaWxsPSIjZmZmZmZmIj48L3BhdGg+Cjwvc3ZnPgo=);
}


/* ==========================================================================
   Venue - Soundboard
   ========================================================================== */

.venue-soundboard {
    margin-bottom: 2em;
}

/* ==========================================================================
   Venue - Landing Header
   ========================================================================== */

/* .venue-landing-header */

.venue-landing-header__image .media {
    padding-bottom: aspect-ratio(5,3);
}
.venue-landing-header__image img {
    width: 100%;
}
.venue-landing-header__introduction {
    position: relative;
    margin-top: -100px;
}
.venue-landing-header__introduction-inner {
    background: #fff;
    padding: 20px 20px 100px 20px;
    font-size: 18px;
}
.venue-landing-header__title{
    @include font-scale(display, 4);
}
@include mq(small) {
    .venue-landing-header__title{
        @include font-scale(display, 6);
    }
    .venue-landing-header__image .media {
        padding-bottom: aspect-ratio(24,11);
    }
}
@include mq(medium) {
    .venue-landing-header__title{
        @include font-scale(display, 7);
    }
    .venue-landing-header__introduction {
        position: relative;
        margin-top: -160px;
    }
    .venue-landing-header__introduction-inner{
        background: #fff;
        padding: 20px 20px 70px 20px;
        width: 80%;
    }

}

.venue-map{
    margin: 1em 0;
}
.venue-map__iframe{
    margin: 0 -5%;
    width: 110%;

    @include mq(medium) {
        margin: 0;
        width: 100%;
    }
}
.venue-map__iframe.has-scroll-off {
  pointer-events: none;
}
