/* ==========================================================================
   Accordion
   ========================================================================== */

.accordion{
    @include reset-list();
    margin: 1em 0;
}
.accordion__item {
    margin-bottom: .5em;
    background-color: get-color(neutral, light);
    padding: 15px;
    cursor: pointer;
}
.accordion__item-title {
    font-family: font-stack(body);
    font-size: 16px;
    margin-bottom: 0;
    background-position: 0 20px;
    font-weight: bold;
}
.accordion__item-description {
    margin-top: 20px;
}

.no-details {
    details > * {
        display: none;
    }
    details > summary:before {
        float: left;
        width: 20px;
        content: '► ';
    }
    details.open > summary:before {
        content: '▼ ';
    }
    details summary {
        display: block;
    }
}
