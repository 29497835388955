/* ==========================================================================
   Timeline
   ========================================================================== */

.timeline-header {
    text-align: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top;
    @include mq(medium) {
        background-attachment: fixed;
    }
}

.timeline-header__inner {
    color: #fff;
    background-color: rgba(0,0,0,.7);
    padding: 9% 0;
}

.timeline-header__year {
    font-size: 40px;
    margin-bottom: 0;
    @include mq(medium) {
        font-size: 70px;
    }
}
.timeline-header__year .lowercase {
    font-size: 80%;
}
.timeline-header__description {
    margin: 0 auto;
    padding: 0 30px;
    font-family: font-stack(domaine);
    font-size: 14px;
    max-width: 780px;

    @include mq(small-major) {
        font-size: 18px;
        padding: 30px 30px 10px 30px;
    }

    @include mq(medium) {
        font-size: 24px;
        max-width: 820px;
    }
}

.timeline-header .share-tool__button {
    color: #fff;
}

.timeline {
    padding: 1em 0;
    background-color: $primaryColor;
}

/* ==========================================================================
   Timeline - Items
   ========================================================================== */

.timeline-item {
    display: block;
    margin: 0 2% 1em;
    max-width: 1200px;
    text-align: center;
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.2);

    @include mq(medium) {
        padding: 20px;
    }
    @include mq(1500px) {
        margin: 0 auto 1em;
    }

}
.timeline-item__description {
    font-size: 12px;
    display: block;
    margin-top: 10px;
}
.timeline-item__title {
    margin: 0;
    font-size: 14px;
    line-height: 1.1em;
}
.timeline-item__video-link {
    position: relative;
    display: block;
}
.timeline-item__video-link .icon-play {
    color: #fff;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    opacity: .75;
}
.timeline-item__video-link:hover .icon-play {
    opacity: 1;
}
.timeline-item__footer {
    margin-top: 10px;

    @include mq($until: small){
        .btn{ font-size: 12px; }
    }
}

.timeline-item.has-media {
    @include clearfix();
    text-align: left;

    .timeline-item__media {
        width: 140px;
        float: right;
        margin-left: $global-baseline;
        margin-bottom: $global-baseline;

        @include mq(medium) {
            width: 200px;
            margin-left: $global-baseline*2;
            margin-bottom: $global-baseline*2;
        }

        @include mq(large) {
            margin-bottom: 0;
        }

        .no-js & {
            display: none;
        }
    }
    .timeline-item__image{
        width: 100%;
    }
}
.timeline-item__image {
    width: 100%;
}

.timeline-item:nth-child(even) {
    .timeline-item__media {
        float: left;
        margin-right: $global-baseline;
        margin-left: 0;

        @include mq(medium) {
            margin-right: $global-baseline*2;
            margin-left: 0;
        }
    }
}

// Timeline Image

.timeline-item--image {
    position: relative;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;
    max-width: none;
    box-sizing: none;
}

.timeline-item--image .timeline-item__caption {
    font-family: font-stack(sans);
    font-size: 12px;
    background-color: #000;
    color: #fff;
    position: absolute;
    bottom: 2%;
    right: 2%;
    max-width: 96%;
    margin: 0;
    padding: 3px 8px;

    @include mq(medium) {
        max-width: 600px;
        text-align: right;
    }
}

// Timeline Quote

.timeline-item--quote {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    background: transparent;
    color: #fff;
    box-shadow: none;

    .o-blockquote {
        padding: 1em 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 780px;
        border-left: none;
        font-size: 12px;
    }
    .o-blockquote__footer {
        font-size: 14px;
        font-style: italic;
    }

    .o-blockquote:before {
        border-top: 1px solid #fff;
    }

    hr {
        width: 20%;
        border: none;
        margin: 0 auto;
        height: 3px;
        background-color: #fff;
    }

    @include mq(xsmall){
        .o-blockquote { font-size: 14px; }

        .o-blockquote__footer { font-size: 18px; }
    }
    @include mq(medium){
        .o-blockquote { font-size: 16px; }
    }
}

// Timeline Playlist

.timeline-item--playlist.has-media {

    .timeline-item__media {
        width: 316px;
    }
    .timeline-item__media iframe{
        width: 100%;
    }
    @include mq($until: small){
        .timeline-item__media {
            width: 100%;
            float: none;
            padding: 10px 0 0 0;
        }
        &:nth-child(even) {
            .timeline-item__media {
                width: 100%;
                float: none;
                padding: 10px 0 0 0;
            }
        }
    }
    @include mq(small){
        .timeline-item__content {
            float: left;
            width: calc(100% - 350px);
        }
    }
}
.timeline-item--playlist.has-media:nth-child(even){
    .timeline-item__content{
        float: right;
    }
}


// Timeline Featured

.timeline-item--featured.has-media {

    @include mq($until: small){
        .timeline-item__media {
            width: 100%;
            float: none;
            padding: 0 0 10px 0;
            margin: 0 0 16px 0;
        }
        &:nth-child(even) {
            .timeline-item__media {
                float: none;
                padding: 0 0 10px 0;
            }
        }
    }
    .timeline-item__video-link .icon-play {
        width: 90px;
        height: 90px;
    }
}

@include mq(small) {
    .timeline-item--featured.has-media {
         .timeline-item__media {
            width: 50%;
        }
    }
}

@include mq(xsmall) {
    .timeline-item__title {
        @include font-scale(display, 1);
    }
}

@include mq(medium) {

    .timeline-item__title {
        margin: 0;
        @include font-scale(display, 5);
    }
    .timeline-item__description {
        font-size: 18px;
    }

    .timeline-item--image {
        height: 350px;
        overflow: hidden;
    }
    .timeline-item__imginline {
        margin-top: -10%;
    }

}

@include mq(medium-major) {

    .timeline-item__imginline {
        display: none;
    }

}
@include mq(large){
    .timeline-item__title {
        @include font-scale(display, 6);
    }
}


/* ==========================================================================
   Timeline - Decade List
   ========================================================================== */


.timeline-decade-item {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    position: relative;
    color: #fff;
}
.timeline-decade-item__image {
    width: 100%;
}
.timeline-decade-item__body {
    padding: 8% 2%;
    background-color: rgba(0,0,0,.75);
    transition: background-color 400ms ease-in-out, padding 400ms ease-in-out;

    &:hover {
        padding: 16% 2%;
        background-color: rgba(0,0,0,.5);
        .timeline-decade-item__title {
            transform: scale(1.1,1.1);
        }
    }
}

.timeline-decade-item__title {
    font-size: 40px;
    margin-bottom: 0;
    position: relative;
    z-index: z-scale(overlay);
    transition: transform 400ms ease-in-out;
    transition-delay: 200ms;

    @include mq(small) {
        font-size: 60px;
    }
}

.timeline-decade-item__title .lowercase {
    font-size: 80%;
}

.timeline-decade-item__description {
    font-size: 20px;
    position: relative;
    z-index: z-scale(overlay);
}

/* ==========================================================================
   Timeline - Related Content
   ========================================================================== */

.related-content {
    .related-content__inner {
        padding-top: .5em;
        border-top: 1px solid get-color(global, border);
    }
    .o-grid__item {
        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid #201d1e;

        &:last-child {
            border-bottom: none;
        }
    }
    .event-item {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;

        .booking-container {
            margin-bottom: 0;
        }

        .performance-select__date {
            margin-bottom: 5px;
        }

        .venue-icon {
            height: 25px;
            padding: 7px 4px 0;
        }
    }

    .soundboard-item {
        @include clearfix();
        padding: 0;
        border: none;

        .soundboard-item__media {
            border: none;
            width: 100px;
            float: right;
            padding-left: 8px;
            background: #fff;
        }
        .soundboard-item__image{
            opacity: 1;
        }
        .soundboard-item__title {
            font-size: 16px;
            padding: .5em 0 0 0;
        }
    }
    .page-item {
        @include clearfix();

        .page-item__media {
            width: 100px;
            float: right;
            padding-left: 8px;
        }
        .page-item__image {
            width: 100%;
        }
        .page-item__title{
            font-size: 18px;
            padding: .5em 0 0 0;
        }
        .page-item__description{
            display: none;
        }
    }
    .timeline-decade-item {
        .timeline-decade-item__media {
            width: 100px;
            float: right;
            padding-left: 8px;
        }
        .timeline-decade-item__body {
            color: #222;
            background-color: transparent;
            transition: none;
            text-align: left;
            padding: 10px 0;

            &:hover { padding: 10px 0; }
        }
        .timeline-decade-item__title { font-size: 18px; }
        .timeline-decade-item__title:hover { transform: none; }
        .timeline-decade-item__description { font-size: 14px; }
    }

    @include mq(small) {
        .soundboard-item {
            .soundboard-item__media { width: 140px; }
        }
        .page-item {
            .page-item__media { width: 140px; }
        }
        .timeline-decade-item {
            .timeline-decade-item__media { width: 140px; }
        }
    }

    @include mq(medium) {

        .related-content__inner {
            padding-top: 1em;
        }

        .o-grid__item{ border: none; }

        .event-item {
            display: block;
            border: none;

            .booking-container {
                margin-bottom: 10px;
            }
        }
        .event-item.has-image {
            .event-item__content, .event-item__media {
                display: block;
            }
            .event-item__media {
                width: 100%;
                padding-left: 0;
                margin-bottom: 15px;
            }
            .event-item__content{
                padding-right: 0;
            }
            .event-item__image {
                width: 100%;
            }
        }

        .soundboard-item {
            padding: 0;
            border: none;

            .soundboard-item__media {
                width: 100%;
                padding-left: 0;
                float: none;
            }
            .soundboard-item__body {
                padding: 0 10px;
                margin-top: 1em;
                border-left: 1px solid #201d1e;
                border-right: 1px solid #201d1e;
            }
            .soundboard-item__title {
                padding: 0;
                font-size: 20px;
            }
        }
        .page-item {
            .page-item__media {
                width: 100%;
                padding-left: 0;
                float: none;
            }
            .page-item__title {
                padding: .5em 0 0 0;
                font-size: 22px;
            }
            .page-item__description {
                display: block;
                font-size: 15px;
            }
        }

        .timeline-decade-item {
            .timeline-decade-item__media {
                width: 100%;
                float: none;
                padding-left: 0;
            }
            .timeline-decade-item__title {
                font-size: 42px;
                margin-bottom: 5px;
            }
            .timeline-decade-item__description { font-size: 18px; }
        }
    }
}



