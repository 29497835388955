/* ==========================================================================
   Messages
   ========================================================================== */

.message-banner {
    padding: 1em;
    margin-bottom: 1em;
    border: 1px solid darken(get-color(message), 15%);
    color: darken(get-color(message), 60%);
    background-color: get-color(message);
}
.message-banner__title {
    font-size: 100%;
    margin: .5em 0;
    text-transform: uppercase;
    text-align: center;
}

.message-banner--success {
    color: get-color(message, success);
    background-color: lighten(get-color(message, success), 50%);
    border-color: lighten(get-color(message, success), 35%);
}
.message-banner--warning {
    color: white;
    background-color: get-color(message, warning);
    border-color: darken(get-color(message, warning), 10%);
}
