/* ==========================================================================
   Section Search
   ========================================================================== */

.global-search {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    margin-bottom: 1em;
}
input[type=search].global-search__input {
    height: 42px;
    line-height: 42px;
    padding: 0 0 0 10px;
    max-width: 350px;
    border: none;
    border-radius: 3px 0 0 3px;
    font-family: font-stack(sans);
    box-sizing: border-box;
    width: 50%;

    @include mq(medium) {
        width: 90%;
    }
}
.global-search__submit {
    height: 42px;
    vertical-align: top;
    border-radius: 0 3px 3px 0;
}

@include mq($until: xsmall) {
    .global-search__input {
        border-radius: 3px;
        margin: 0 10px;
        width: 80%;
    }
    .global-search__submit{ @include visually-hidden(); }
}

/* ==========================================================================
   Section Search - Search Header
   ========================================================================== */

.search-header {
    padding: 2em 10px;
    margin-bottom: 2em;
    background-color: get-color(neutral, light);
}
.search-header__query-details {
    text-align: center;
    display: block;
}
.search-header__query {
    font-weight: bold;
}

/* ==========================================================================
   Section Search - Search Page Nav
   ========================================================================== */

.search-nav {
    text-align: center;
    margin: 0;
    padding: 0;
}
.search-nav__list{
    @include reset-list();
}
.search-nav__item {
    display: inline-block;
    text-transform: uppercase;
    margin: 0 1em;

    @include mq($until: xsmall) {
        font-size: 12px;
    }
}
.search-nav__link {
    display: block;
}
.search-nav__link.is-active {
    border-bottom: 3px solid $primaryColor;
}

/* ==========================================================================
   Section Search - Search Page Results
   ========================================================================== */

.search-results {
    padding: 0 2%;
}
.search-results__group {
    margin: 2em 0 3em 0;
}
.search-results__header {
    border: 1px solid get-color(neutral, light);
    border-width: 1px 0;
    text-align: center;
    padding: .5em 0;
    margin-bottom: 1em;
}
.search-results__title {
    margin: 0;
    font-size: 24px;
}
.search-results__more {
    background-color: get-color(neutral, light);
    display: block;
    padding: .5em;
    text-align: center;
    text-transform: uppercase;
    font-family: font-stack(trim);
}
.search-results__more:hover {
    background-color: get-color(neutral);
}

@include mq(medium) {
    .search-results {
        padding: 0 10%;
    }
}

/* ==========================================================================
   Section Search - Search Page Soundboard
   ========================================================================== */

.soundboard-item--search{
    display: block;
    padding: .5em ($global-baseline / 2) 1em;
    margin-bottom: $global-baseline;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid get-color(brand, secondary);
}
.soundboard-item--search{
    .soundboard-item__title{
        padding: 0;
        font-size: 14px;
        font-family: font-stack(domaine);
    }
}

@include mq(medium) {
    .soundboard-item--search{
        padding: 1em ($global-baseline / 2) 1.5em;
        .soundboard-item__title{
            font-size: 18px;
        }
    }
}


/* ==========================================================================
   Section Search - Search Page Information
   ========================================================================== */

.information-item{
    display: block;
    padding: 1em ($global-baseline / 2) 1.5em;
    margin-bottom: ($global-baseline / 2);
    border-bottom: 1px solid get-color(brand, secondary);
}

.information-item__title{
    padding: 0;
    font-size: 14px;
    font-family: font-stack(display);
}
.information-item__description{
    font-size: 14px;
    padding: 0;

    .highlight {
        font-weight: bold;
    }
}
.information-item__url{
    display: block;
    font-size: 14px;
    text-decoration: underline;
}

@include mq(medium) {
    .information-item__title{
        font-size: 18px;
    }
}
