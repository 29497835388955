//  -------------------------------------------------------- //
//  Functions
//  -------------------------------------------------------- //

// Syntax sugar for intrinsic-ratio calculations
@function aspect-ratio($width, $height) {
    @return percentage($height/$width);
}

@function z-scale($key) {
    @return map-get($z-scale, $key);
}
