/* ==========================================================================
   Homepage Feature
   ========================================================================== */

.homepage-features {
    margin: 3em 0;
}

.homepage-features__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    padding: 0;

    @include mq(small-major) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em;
    }

    @include mq(large) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.homepage-feature {
    display: block;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding-left: 10px;
    padding-right: 10px;
    transition: border-color .25s ease-in-out;
    &:hover { border-color: #000; }
}

.homepage-feature__title {
    font-size: 18px;
    font-family: font-stack(domaine);
    font-style: italic;
    padding: 10px;
    text-transform: none;
    line-height: 1.2;
}

.homepage-feature__media {
    overflow: hidden;
    background-color: #000;
}

.homepage-feature__image {
    width: 100%;
    height: 100%;
    transition: transform 2s ease-in-out, opacity 2s ease-in-out;
    transition-delay: 0.25s;
    opacity: 0.75;
}

.homepage-feature:hover .homepage-feature__image {
    transform: scale(1.1, 1.1);
    opacity: 1;
}

@include mq(medium) {
    .homepage-features-grid__article--featured {
        .homepage-feature:hover {
            .homepage-feature__image {
                transform: scale(1.1, 1.1);
            }
        }
    }
}
