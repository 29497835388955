/*  stylelint-disable */
/*  =========================================================================
    Scopes: Prose
    ========================================================================= */
/*
    Opt-in typography:
    http://anthonyshort.me/global-typographic-styles-suck
    http://dbushell.com/2012/04/18/scoping-typography-css/
    http://css-tricks.com/opt-in-typography/
*/
.s-prose {

    font-size: 17px;

    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 .3em 0;
        line-height: 1;
    }
    h3, h4, h5, h6 {
        margin-top: .5em;
    }

    p:empty { display: none; }

    a:not(.inline-button),
    a:not(.inline-button):visited,
    a:not(.inline-button):hover,
    a:not(.inline-button):active {
        font-weight: bold;
        text-decoration: underline;
        color: $primaryColor;
    }

    .inline-button {
        margin-bottom: $global-baseline / 2;
    }

    .inline-image {
        display: inline-block;
    }

    .inline-image--align-left{
        float: left;
        margin: 10px 15px 10px 0;
    }
    .inline-image--align-right{
        float: right;
        margin: 10px 0 10px 15px;
    }

    hr {
        max-width: 15em;
        margin: 2em auto;
        border: none;
        border-top: 1px solid get-color(neutral);
    }

    blockquote {
        @include blockquote();
    }

    .well {
        @include well();
    }
    iframe{
        margin: 1em 0;
        width: 100%;
    }
    .fluid-width-video-wrapper iframe{
        margin: 0;
    }
    .fluid-width-video-wrapper{
        margin-bottom: 1em;
    }

    @include mq($until: small) {
        h2{ @include font-scale(display, 4); }
        h3{ @include font-scale(display, 3); }
        h4{ @include font-scale(display, 2); }
        h5{ @include font-scale(display, 1); }
    }
}
