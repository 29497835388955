/* ==========================================================================
   Corporation
   ========================================================================== */


.corporation-venues{
    @include reset-list();
    display: flex;
    margin-bottom: 2em;
    border: 2px solid #fff;
    flex-wrap: wrap;

    @include mq(large) {
        flex-wrap: nowrap;
    }
}

.corporation-venues__venue-item{
    flex-basis: 50%;
    border: 2px solid #fff;
}

/* ==========================================================================
   Coporation - Venue
   ========================================================================== */

.corporation-venue{
    position: relative;
}

.corporation-venue__header{
    display: flex;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    z-index: z-scale(foreground);
}

.corporation-venue__logo {
    width: 30px;
    margin-right: 5px;
    background-color: rgba(0, 0, 0, 0.75);

    @include mq($until: small-major) {
        display: none;
    }

    @include mq(medium) {
        .corporation-venues:not(.corporation-venues--4-up) & {
            width: 40px;
        }
    }

    @include mq(xlarge) {
        .corporation-venues:not(.corporation-venues--4-up) & {
            width: 50px;
        }
    }

    @include mq(xxlarge) {
        width: 50px;
    }
}

.corporation-venue__title {
    flex: 1;
    color: #fff;
    height: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: get-color(brand, massey);

    @include mq(medium) {
        .corporation-venues:not(.corporation-venues--4-up) & {
            height: 40px;
            line-height: 40px;
            padding: 0 15px;
            font-size: 16px;
        }
    }

    @include mq(xlarge) {
        .corporation-venues:not(.corporation-venues--4-up) & {
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            font-size: 20px;
        }
    }

    @include mq(xxlarge) {
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        font-size: 20px;
    }
}

.corporation-venue__media {
    position: relative;
    padding-top: 83.3333333333%;
}

.corporation-venue__image {
    position: absolute;
    width: 100%;
    height: auto;
    filter: grayscale(100%);
    transition: all 2s filter;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .corporation-venues__venue-item:hover & {
        @include mq(large) {
            filter: grayscale(0);
        }
    }
}

.corporation-venue.massey-hall{
    .corporation-venue__title {
        background-color: get-color(brand, massey);
        font-family: font-stack(trim);
    }
}
.corporation-venue.roy-thomson-hall{
    .corporation-venue__title {
        background-color: get-color(brand, thomson);
        font-family: font-stack(domaine);
    }
}
.corporation-venue.td-music-hall{
    .corporation-venue__title {
        background-color: get-color(brand, td);
    }
}
