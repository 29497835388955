/* ==========================================================================
   Reviews
   ========================================================================== */

.review {
    margin: 2em 0;
}

.review__rating {
    .icon-star{
        fill: #d7b420;
    }
}
.review__description {
    .s-prose & {
        padding-left: 0;
        border-left: none;
    }
    p {
        font-size: 18px;
        margin-bottom: 0;
        font-family: font-stack(domaine);
    }
}
.review__meta {
    font-size: 18px;
}
.s-prose .publisher{
    a:not(.inline-button), a:not(.inline-button):hover { text-decoration: none; }
}

@include mq(small) {
    .review__description p{
        font-size: 26px;
    }
}
