/* ==========================================================================
   Media
   ========================================================================== */

/* ==========================================================================
   Media: Video item
   ========================================================================== */

.video-item {
    display: block;
    background-color: get-color(neutral, light);
    padding: ($global-baseline / 2);
    margin-bottom: ($global-baseline / 2);
}
.video-item__title {
    margin: 0;
    font-size: 18px;
    font-family: font-stack(domaine);
    font-weight: bold;
    text-transform: none;
}
.video-item__media {
    width: 100px;
    padding-left: $global-baseline / 2;
    margin-bottom: $global-baseline / 2;
    float: right;

    @include mq(medium) {
        width: 130px;
    }

    .no-js & {
        display: none;
    }
}
.video-item__meta {
    font-size: 12px;
    display: block;
}
.video-item__footer{
    margin-top: 10px;
}

.video-item--large{
    .video-item__title { font-size: 20px; }
    .video-item__meta {
        font-size: 16px;
        margin-top: .25em;
    }
    .video-item__description { font-size: 14px; }
}

/* ==========================================================================
   Media: video grouping
   ========================================================================== */

.video-grouping {
    @include clearfix();
    margin-bottom: $global-baseline * 2;
    background-color: #000;
    padding: $global-baseline;

    .video-grouping__title{
        font-family: font-stack(domaine);
        color: get-color(lamh, secondary);
        text-transform: none;
        font-weight: bold;
    }
    .video-item{
        background-color: transparent;
        color: #fff;
        padding: $global-baseline 0 ($global-baseline / 2) 0;
        border-top: 1px solid get-color(lamh, secondary);

        &:last-child{
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}
.video-grouping__title {
    font-weight: bold;
    text-align: center;
    margin: 0 0 $global-baseline 0;
}

/* ==========================================================================
   Media: Live at Massey Hall
   ========================================================================== */

.live-grouping {
    @include clearfix();
    margin-bottom: $global-baseline * 2;
    background-color: #000;
    padding: $global-baseline;

    .live-grouping__title{
        font-family: font-stack(domaine);
        color: get-color(lamh, secondary);
        text-transform: none;
        font-weight: bold;
    }
}
.live-grouping__title {
    font-weight: bold;
    text-align: center;
    margin: 0 0 $global-baseline 0;
}

/* ==========================================================================
   Media: Resource List
   ========================================================================== */

.resource-list{
    @include reset-list();
    margin: 2em 0;
}
.resource-list__item,
.resource-list__link {
    display: block;
}
.resource-list__item{
    margin-bottom: 5px;
    padding-top: 1em;
    border-top: 1px solid get-color(global, border);

    .icon-download{ vertical-align: top; }

    &:hover{
        .icon-download{
            outline: #eaeaea;
        }
    }
}
.resource-list__body{
    display: inline-block;
    width: 70%;
}
.resource-list__title{
    font-family: font-stack(body);
    font-size: 16px;
    text-transform: none;
    margin-bottom: 2px;

    @include mq(small) {
        font-size: 20px;
    }
}

/* ==========================================================================
   Media: Inline Gallery
   ========================================================================== */

.inline-gallery{
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: -$global-offset;
    margin-right: -$global-offset;
    position: relative;

    @include mq(small) {
        margin: 1em 0;
    }

    .slick-prev, .slick-next {
        position: absolute;
        top: 50%;
        display: block;
        width: 30px;
        height: 30px;
        margin-top: -15px;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: 10px;
        border-radius: 20px;
        border: 1px solid #fff;
        z-index: 920;
        text-indent: -999999px;
        overflow: hidden;
        -webkit-appearance: none;
        opacity: 0;
        transition: opacity 200ms ease-in;
        &:focus{
            outline: none;
        }
    }
    .slick-next {
        background-image: url(../images/icon-chevron-right.svg);
        background-position: 55% center;
        right: 15px;
    }
    .slick-prev {
        background-image: url(../images/icon-chevron-left.svg);
        background-position: 45% center;
        left: 15px;
    }
}

.inline-gallery__list{
    @include reset-list();
}
.inline-gallery__item{
    display: none;

    &:first-child{
        display: block;
    }

    .gallery-item{
        margin-bottom: 0;
    }
    .gallery-item__link{
        position: absolute;
        top: 15px; right: 15px;
        z-index: 900;
        background-color: rgba(0,0,0,0.35);
        color: #fff;
        border: none;
        appearance: none;
        border-radius: 30px;
        display: block;
        width: 45px;
        height: 45px;
        padding: 3px 8px;
        display: none;
        &:hover {
            background-color: $primaryColor;
            color: #000;
        }
    }
    .gallery-item__image{
        width: 100%;
    }
    .gallery-item__meta{
        position: absolute;
        bottom: -180px;
        padding: 15px;
        width: 100%;
        font-family: font-stack(sans);
        background-color: rgba(0, 0, 0, 0.75);
        opacity: 0;
        transition: opacity 800ms ease-in-out, bottom 800ms ease-in-out;
        transition-delay: 200ms;
    }
    .gallery-item__slide-number{
        float: right;
        margin: 0 0 5px 10px;
        font-size: 12px;
        color: #fff;
    }
    .gallery-item__caption{
        position: relative;
        bottom: auto;
        right: auto;
        padding: 0;
        background: none;
        font-size: 12px;
        color: #fff;
    }
    .gallery-item__credit{
        font-size: 12px;
        color: #ccc;
    }
}

.inline-gallery:hover {
    .gallery-item__meta {
        opacity: 1;
        bottom:0;
    }
    .slick-prev, .slick-next { opacity: 1; }
}

.slick-initialized .gallery-item__link{
    display: block;
}

.inline-gallery--align-left{
    @include mq(large) {
        width: 60%;
        float: left;
        margin: 0 1.5em .5em 0;
    }
}
.inline-gallery--align-right{
    @include mq(large) {
        width: 60%;
        float: right;
        margin: 0 0 .5em 1.5em;

    }
}


/* ==========================================================================
   Media: Inline Gallery - Photoswipe
   ========================================================================== */

$pswp__show-hide-transition-duration: 333ms !default;
$pswp__controls-transition-duration: 333ms !default;
$pswp__background-color: #000 !default;
$pswp__placeholder-color: #222 !default;
$pswp__box-sizing-border-box: true !default; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
$pswp__root-z-index: 99999 !default;
$pswp__assets-path: '../images/' !default; // path to skin assets folder (preloader, PNG and SVG sprite)
$pswp__error-text-color: #ccc !default; // "Image not loaded" text color
$pswp__include-minimal-style: true !default;

/* pswp = photoswipe */
.pswp {
    display: none;
    position:absolute;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
    z-index: $pswp__root-z-index;
    -webkit-text-size-adjust: 100%;
    /* create separate layer, to avoid paint on window.onscroll in webkit/blink */
    -webkit-backface-visibility: hidden;
    outline: none;

    img {
        max-width: none;
    }
}

/* style is added when JS option showHideOpacity is set to true */
.pswp--animate_opacity {
    /* 0.001, because opacity:0 doesn't trigger Paint action, which causes lag at start of transition */
    opacity: 0.001;
    will-change:opacity;
    /* for open/close transition */
    transition: opacity $pswp__show-hide-transition-duration cubic-bezier(.4,0,.22,1);
}

.pswp--open {
    display: block;
}

.pswp--zoom-allowed .pswp__img {
    /* autoprefixer: off */
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
    /* autoprefixer: off */
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.pswp--dragging .pswp__img {
    /* autoprefixer: off */
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}

/*
    Background is added as a separate element.
    As animating opacity is much faster than animating rgba() background-color.
*/
.pswp__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $pswp__background-color;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    will-change:opacity;
}

.pswp__scroll-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow:hidden;
}

.pswp__container,
.pswp__zoom-wrap {
    -ms-touch-action: none;
    touch-action: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #222;
}

/* Prevent selection and tap highlights */
.pswp__container,
.pswp__img {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
    position: absolute;
    width: 100%;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    /* for open/close transition */
    transition: transform $pswp__show-hide-transition-duration cubic-bezier(.4,0,.22,1);
}

.pswp__bg {
    will-change: opacity;
    /* for open/close transition */
    transition: opacity $pswp__show-hide-transition-duration cubic-bezier(.4,0,.22,1);
}

.pswp--animated-in {
    .pswp__bg,
    .pswp__zoom-wrap {
        -webkit-transition: none;
        transition: none;
    }
}

.pswp__container,
.pswp__zoom-wrap {
    -webkit-backface-visibility: hidden;
}

.pswp__item {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    background: #222;
}

.pswp__img {
    position: absolute;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
}

/*
    stretched thumbnail or div placeholder element (see below)
    style is added to avoid flickering in webkit/blink when layers overlap
*/
.pswp__img--placeholder {
    -webkit-backface-visibility: hidden;
}

/*
    div element that matches size of large image
    large image loads on top of it
*/
.pswp__img--placeholder--blank {
    background: $pswp__placeholder-color;
}

.pswp--ie .pswp__img {
    width: 100% !important;
    height: auto !important;
    left: 0;
    top: 0;
}

/*
    Error message appears when image is not loaded
    (JS option errorMsg controls markup)
*/
.pswp__error-msg {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    margin-top: -8px;
    color: $pswp__error-text-color;
}

.pswp__error-msg a {
    color: $pswp__error-text-color;
    text-decoration: underline;
}

/*

    Contents:

    1. Buttons
    2. Index indicator ("1 of X" counter)
    3. Caption
    4. Loading indicator
    5. Additional styles (root element, top bar, idle state, hidden state, etc.)

*/

/*

    1. Buttons

 */

/* <button> css reset */
.pswp__button {
    width: 44px;
    height: 44px;
    position: relative;
    background: none;
    cursor: pointer;
    overflow: visible;
    -webkit-appearance: none;
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    float: right;
    opacity: 0.75;
    transition: opacity 0.2s;
    box-shadow: none;

    &:focus,
    &:hover {
        opacity: 1;
    }

    &:active {
        outline: none;
        opacity: 0.9;
    }

    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
}

/* pswp__ui--over-close class it added when mouse is over element that should close gallery */
.pswp__ui--over-close .pswp__button--close {
    opacity: 1;
}

.pswp__button {
    background-image: url(#{$pswp__assets-path}default-skin.svg);
    background-size: 264px 88px;
    width: 44px;
    height: 44px;
}

.pswp__button--close {
    background-position: 0 -44px;
}

.pswp__button--share {
    background-position: -44px -44px;
}

.pswp__button--fs {
    display: none;
}

.pswp--supports-fs .pswp__button--fs {
    display: block;
}

.pswp--fs .pswp__button--fs {
    background-position: -44px 0;
}

.pswp__button--zoom {
    display: none;
    background-position: -88px 0;
}

.pswp--zoom-allowed .pswp__button--zoom {
    display: block;
}

.pswp--zoomed-in .pswp__button--zoom {
    background-position: -132px 0;
}

/* no arrows on touch screens */
.pswp--touch {
    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
        visibility: hidden;
    }
}

/*
    Arrow buttons hit area
    (icon is added to :before pseudo-element)
*/
.pswp__button--arrow--left,
.pswp__button--arrow--right {
    background: none;
    top: 50%;
    margin-top: -50px;
    width: 70px;
    height: 100px;
    position: absolute;
    z-index: 500;
}

.pswp__button--arrow--left {
    left: 0;
}

.pswp__button--arrow--right {
    right: 0;
}

.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
    content: '';
    top: 35px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 13px;
    border-radius: 35px;
    height: 35px;
    width: 35px;
    position: absolute;

}

.pswp__button--arrow--left:before {
    left: 6px;
    background-image: url(../images/icon-chevron-left.svg);
    background-position: 45% center;
}

.pswp__button--arrow--right:before {
    right: 6px;
    background-image: url(../images/icon-chevron-right.svg);
    background-position: 55% center;
}


/*
    2. Index indicator ("1 of X" counter)

 */

.pswp__counter {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    font-family: font-stack(sans);
    font-weight: bold;
    font-size: 14px;
    line-height: 44px;
    color: #fff;
    opacity: 0.75;
    padding: 0 10px;
}


/*

    3. Caption

 */

.pswp__caption {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 44px;
    font-family: font-stack(sans);
    font-size: 14px;
}

.pswp__credit {
    color: #ccc;
    font-size: 12px;
}

.pswp__caption__center {
    text-align: left;
    max-width: 720px;
    margin: 0;
    font-size: 13px;
    padding: 15px;
    line-height: 20px;
    color: #fff;
}

.pswp__caption--empty {
    display: none;
}

/* Fake caption element, used to calculate height of next/prev image */
.pswp__caption--fake {
    visibility: hidden;
}


/*

    4. Loading indicator (preloader)

    You can play with it here - http://codepen.io/dimsemenov/pen/yyBWoR

 */

.pswp__preloader {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -22px;
    opacity: 0;
    transition: opacity 0.25s ease-out;
    will-change: opacity;
    direction: ltr;
}

.pswp__preloader__icn {
    width: 20px;
    height: 20px;
    margin: 12px;
}

.pswp__preloader--active {
    opacity: 1;

    .pswp__preloader__icn {
        /* We use .gif in browsers that don't support CSS animation */
        background: url(data:image/gif;base64,R0lGODlhFAAUAPMIAIeHhz8/P1dXVycnJ8/Pz7e3t5+fn29vb////wAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFBwAIACwAAAAAFAAUAEAEUxDJSatFxtwaggWAdIyHJAhXoRYSQUhDPGx0TbmujahbXGWZWqdDAYEsp5NupLPkdDwE7oXwWVasimzWrAE1tKFHErQRK8eL8mMUlRBJVI307uoiACH5BAUHAAgALAEAAQASABIAAAROEMkpS6E4W5upMdUmEQT2feFIltMJYivbvhnZ3R0A4NMwIDodz+cL7nDEn5CH8DGZh8MtEMBEoxkqlXKVIgQCibbK9YLBYvLtHH5K0J0IACH5BAUHAAgALAEAAQASABIAAAROEMkpjaE4W5spANUmFQX2feFIltMJYivbvhnZ3d1x4BNBIDodz+cL7nDEn5CH8DGZAsFtMMBEoxkqlXKVIgIBibbK9YLBYvLtHH5K0J0IACH5BAUHAAgALAEAAQASABIAAAROEMkpAaA4W5vpOdUmGQb2feFIltMJYivbvhnZ3Z0g4FNRIDodz+cL7nDEn5CH8DGZgcCNQMBEoxkqlXKVIgYDibbK9YLBYvLtHH5K0J0IACH5BAUHAAgALAEAAQASABIAAAROEMkpz6E4W5upENUmAQD2feFIltMJYivbvhnZ3V0Q4JNhIDodz+cL7nDEn5CH8DGZg8GtUMBEoxkqlXKVIggEibbK9YLBYvLtHH5K0J0IACH5BAUHAAgALAEAAQASABIAAAROEMkphaA4W5tpCNUmHQf2feFIltMJYivbvhnZ3d0w4BMAIDodz+cL7nDEn5CH8DGZBMLNYMBEoxkqlXKVIgoFibbK9YLBYvLtHH5K0J0IACH5BAUHAAgALAEAAQASABIAAAROEMkpQ6A4W5vpGNUmCQL2feFIltMJYivbvhnZ3R1B4NNxIDodz+cL7nDEn5CH8DGZhcINAMBEoxkqlXKVIgwGibbK9YLBYvLtHH5K0J0IACH5BAUHAAcALAEAAQASABIAAANCeLo6wzA6FxkhbaoQ4L3ZxnXLh0EjWZ4RV71VUcCLIByyTNt2PsO8m452sBGJBsNxkUwuD03lAQBASqnUJ7aq5UYSADs=) 0 0 no-repeat;
    }
}

.pswp--css_animation {
    .pswp__preloader--active {
        opacity: 1;

        .pswp__preloader__icn {
            animation: clockwise 500ms linear infinite;
        }

        .pswp__preloader__donut {
            animation: donut-rotate 1000ms cubic-bezier(.4,0,.22,1) infinite;
        }
    }

    .pswp__preloader__icn {
        background: none;
        opacity: 0.75;
        width: 14px;
        height: 14px;
        position: absolute;
        left: 15px;
        top: 15px;
        margin: 0;
    }


    .pswp__preloader__cut {
        /*
            The idea of animating inner circle is based on Polymer ("material") loading indicator
             by Keanu Lee https://blog.keanulee.com/2014/10/20/the-tale-of-three-spinners.html
        */
        position: relative;
        width: 7px;
        height: 14px;
        overflow: hidden;
    }

    .pswp__preloader__donut {
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        border: 2px solid #fff;
        border-radius: 50%;
        border-left-color: transparent;
        border-bottom-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        background: none;
        margin: 0;
    }
}

@media screen and (max-width: 1024px) {
    .pswp__preloader {
        position: relative;
        left: auto;
        top: auto;
        margin: 0;
        float: right;
    }
}

@keyframes clockwise {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes donut-rotate {
  0% { transform: rotate(0); }
  50% { transform: rotate(-140deg); }
  100% { transform: rotate(0); }
}


/*

    5. Additional styles

 */

/* root element of UI */

.pswp__ui {
    -webkit-font-smoothing: auto;
    visibility: visible;
    opacity: 1;
    z-index: $pswp__root-z-index + 50;
    transition: opacity 1s ease-in-out;
    // width: 100%;
    // @include mq(medium) {
    //     background-color: rgba(0,0,0,0.5);
    //     right: 0;
    //     position: absolute;
    //     width: 220px;
    //     height: 100%;
    // }
    // @include mq(xlarge) {
    //     width: 280px;
    // }
}


/* top black bar with buttons and "1 of X" indicator */
.pswp__top-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    width: 100%;
}

.pswp__caption,
.pswp__top-bar,
.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right {
    -webkit-backface-visibility: hidden;
    will-change: opacity;
    transition: opacity $pswp__controls-transition-duration cubic-bezier(.4,0,.22,1);
    z-index: $pswp__root-z-index + 100;
}

/* pswp--has_mouse class is added only when two subsequent mousemove events occur */
.pswp--has_mouse {
    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
        visibility: visible;
    }
}

.pswp__top-bar,
.pswp__caption {
    background-color: rgba(0,0,0,0.5);
}

/* pswp__ui--fit class is added when main image "fits" between top bar and bottom bar (caption) */
.pswp__ui--fit {
    .pswp__top-bar,
    .pswp__caption {
        background-color: rgba(0,0,0,0.3);
    }
}

/* pswp__ui--idle class is added when mouse isn't moving for several seconds (JS option timeToIdle) */

.pswp__ui--idle.pswp__ui {
    opacity: 0;
}

/*
    pswp__ui--hidden class is added when controls are hidden
    e.g. when user taps to toggle visibility of controls
*/
.pswp__ui--hidden {
    .pswp__top-bar,
    .pswp__caption,
    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
        /* Force paint & create composition layer for controls. */
        opacity: 0.001;
    }
}

/* pswp__ui--one-slide class is added when there is just one item in gallery */
.pswp__ui--one-slide {
    .pswp__button--arrow--left,
    .pswp__button--arrow--right,
    .pswp__counter {
        display: none;
    }
}

.pswp__element--disabled {
    display: none !important;
}


/* ==========================================================================
   Media: Modals - Magnific
   ========================================================================== */

// overlay
$mfp-overlay-color:                   #0b0b0b !default;                    // Color of overlay screen
$mfp-overlay-opacity:                 0.8 !default;                        // Opacity of overlay screen
$mfp-shadow:                          0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              8px !default;                        // Padding from left and from right side
$mfp-popup-padding-left-mobile:       6px !default;                        // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    1040 !default;                       // Base z-index of popup

// controls
$mfp-include-arrows:                  true !default;                       // Include styles for nav arrows
$mfp-controls-opacity:                0.65 !default;                       // Opacity of controls
$mfp-controls-color:                  #fff !default;                       // Color of controls
$mfp-controls-border-color:           #3f3f3f !default;                      // Border color of controls
$mfp-inner-close-icon-color:          #333 !default;                       // Color of close button when inside
$mfp-controls-text-color:             #ccc !default;                       // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #fff !default;                       // Hover color of preloader and "1 of X" indicator

// Iframe-type options
$mfp-include-iframe-type:             true !default;                       // Enable Iframe-type popups
$mfp-iframe-padding-top:              40px !default;                       // Iframe padding top
$mfp-iframe-background:               #000 !default;                       // Background color of iframes
$mfp-iframe-max-width:                900px !default;                      // Maximum width of iframes
$mfp-iframe-ratio:                    9/16 !default;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              true !default;                       // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               40px !default;                       // Image padding top
$mfp-image-padding-bottom:            40px !default;                       // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default;                       // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             #f3f3f3 !default;                    // Caption title color
$mfp-caption-subtitle-color:          #bdbdbd !default;                    // Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false !default;                      // Hide content from browsers, but make it available for screen readers


// Transluscent overlay
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 2;
  overflow: hidden;
  position: fixed;

  background: $mfp-overlay-color;
  opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 3;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 $mfp-popup-padding-left;
  box-sizing: border-box;
}

// Vertical centerer helper
.mfp-container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top {
  .mfp-container {
    &:before {
      display: none;
    }
  }
}

// Popup content holder
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: $mfp-z-index-base + 5;
}
.mfp-inline-holder,
.mfp-ajax-holder {
  .mfp-content {
    width: 100%;
    cursor: auto;
  }
}

// Cursors
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur {
  &, .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor {
  .mfp-content {
    cursor: auto;
  }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select:none;
  -moz-user-select: none;
  user-select: none;
}

// Hide the image during the loading
.mfp-loading {
  &.mfp-figure {
    display: none;
  }
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden {
  // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
  .mfp-hide {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
}
@else {
  .mfp-hide {
    display: none !important;
  }
}


// Preloader and text that displays error messages
.mfp-preloader {
  color: $mfp-controls-text-color;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: $mfp-z-index-base + 4;
  a {
    color: $mfp-controls-text-color;
    &:hover {
      color: $mfp-controls-text-color-hover;
    }
  }
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
  .mfp-preloader {
    display: none;
  }
}

// Hide content when it was not loaded
.mfp-s-error {
  .mfp-content {
    display: none;
  }
}

// CSS-reset for buttons
button {
  &.mfp-close,
  &.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: $mfp-z-index-base + 6;
    box-shadow: none;
    touch-action: manipulation;
  }
  &::-moz-focus-inner {
      padding: 0;
      border: 0;
  }
}


// Close icon
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;

  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: $mfp-controls-opacity;
  padding: 0 0 18px 10px;
  color: $mfp-controls-color;

  font-style: normal;
  font-size: 28px;
  font-family: font-stack(sans);

  &:hover,
  &:focus {
    opacity: 1;
  }

  &:active {
    top: 1px;
  }
}
.mfp-close-btn-in {
  .mfp-close {
    color: $mfp-inner-close-icon-color;
  }
}
.mfp-image-holder,
.mfp-iframe-holder {
  .mfp-close {
    color: $mfp-controls-color;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
  }
}

// "1 of X" counter
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: $mfp-controls-text-color;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

// Navigation arrows
@if $mfp-include-arrows {
  .mfp-arrow {
    position: absolute;
    opacity: $mfp-controls-opacity;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:active {
      margin-top: -54px;
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 35px;
      margin-left: 35px;
      border: medium inset transparent;
    }

    &:after {

      border-top-width: 13px;
      border-bottom-width: 13px;
      top:8px;
    }

    &:before {
      border-top-width: 21px;
      border-bottom-width: 21px;
      opacity: 0.7;
    }

  }

  .mfp-arrow-left {
    left: 0;
    &:after {
      border-right: 17px solid $mfp-controls-color;
      margin-left: 31px;
    }
    &:before {
      margin-left: 25px;
      border-right: 27px solid $mfp-controls-border-color;
    }
  }

  .mfp-arrow-right {
    right: 0;
    &:after {
      border-left: 17px solid $mfp-controls-color;
      margin-left: 39px;
    }
    &:before {
      border-left: 27px solid $mfp-controls-border-color;
    }
  }
}



// Iframe content type
@if $mfp-include-iframe-type {
  .mfp-iframe-holder {
    padding-top: $mfp-iframe-padding-top;
    padding-bottom: $mfp-iframe-padding-top;
    .mfp-content {
      line-height: 0;
      width: 100%;
      max-width: $mfp-iframe-max-width;
    }
    .mfp-close {
      top: -40px;
    }
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: $mfp-iframe-ratio * 100%;
    iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: $mfp-shadow;
      background: $mfp-iframe-background;
    }
  }
}



// Image content type
@if $mfp-include-image-type {

  /* Main image in popup */
  img {
    &.mfp-img {
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
      line-height: 0;
      box-sizing: border-box;
      padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
      margin: 0 auto;
    }
  }

  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: $mfp-image-padding-top;
      bottom: $mfp-image-padding-bottom;
      display: block;
      right: 0;
      width: auto;
      height: auto;
      z-index: -1;
      box-shadow: $mfp-shadow;
      background: $mfp-image-background;
    }
    small {
      color: $mfp-caption-subtitle-color;
      display: block;
      font-size: 12px;
      line-height: 14px;
    }
    figure {
      margin: 0;
    }
  }
  .mfp-bottom-bar {
    margin-top: -$mfp-image-padding-bottom + 4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    text-align: left;
    line-height: 18px;
    color: $mfp-caption-title-color;
    word-wrap: break-word;
    padding-right: 36px; // leave some space for counter at right side
  }

  .mfp-image-holder {
    .mfp-content {
      max-width: 100%;
    }
  }

  .mfp-gallery {
    .mfp-image-holder {
      .mfp-figure {
        cursor: pointer;
      }
    }
  }


  @if $mfp-include-mobile-layout-for-image {
    @media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
      /**
       * Remove all paddings around the image on small screen
       */
      .mfp-img-mobile {
        .mfp-image-holder {
          padding-left: 0;
          padding-right: 0;
        }
        img {
          &.mfp-img {
            padding: 0;
          }
        }
        .mfp-figure {
          // The shadow behind the image
          &:after {
            top: 0;
            bottom: 0;
          }
          small {
            display: inline;
            margin-left: 5px;
          }
        }
        .mfp-bottom-bar {
          background: rgba(0,0,0,0.6);
          bottom: 0;
          margin: 0;
          top: auto;
          padding: 3px 5px;
          position: fixed;
          box-sizing: border-box;
          &:empty {
            padding: 0;
          }
        }
        .mfp-counter {
          right: 5px;
          top: 3px;
        }
        .mfp-close {
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          line-height: 35px;
          background: rgba(0, 0, 0, 0.6);
          position: fixed;
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}



// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: $mfp-popup-padding-left-mobile;
    padding-right: $mfp-popup-padding-left-mobile;
  }
}




.image-gallery-flat {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0;

    .gallery-item__image {
        height: 160px;
        margin: 5px;
        cursor: pointer;
    }

    .pswp__zoom-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pswp__img {
        position: relative;
    }
}
