/* ==========================================================================
   Spotlight
   ========================================================================== */
.spotlight {
    transition: opacity 200ms ease-in;
    opacity: 1;
}

.spotlight.has-spotlight-activated {
    opacity: .3;
}

.spotlight.has-spotlight-on {
    opacity: 1;
}
