/* ==========================================================================
   Global Sidebar
   ========================================================================== */

.global-sidebar {
    color: white;
    background-color: black;
    font-family: font-stack(sans);

    @include mq(medium-major) {
        position: fixed;
        left: 0; top: 0;
        width: $global-sidebar-width;
        height: 100%;
        border-bottom: 0;

        @media (max-height: 825px) {
            position: absolute;
            left: -$global-sidebar-width; top: 0;
        }
    }

    @include mq(xlarge) {
        width: $global-sidebar-width--large;
        padding: 0 10px;

        @media (max-height: 825px) {
            left: -$global-sidebar-width--large;
        }
    }
}
.global-sidebar__navigation,
.global-sidebar__social,
.global-sidebar__search {
    color: inherit;
}

/* ==========================================================================
   Global Sidebar - Branding
   ========================================================================== */

.global-sidebar__branding {
    position: relative;
    display: block;
    margin: 0 auto;
    z-index: z-scale(foreground);
    padding: 10px 0;
    text-align: center;

    @include mq(medium-major) {
        padding: 20px 20px 10px;
        width: 180px;
        margin: 0;
    }
}

.global-branding {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    height: 30px;
    width: 250px;
    background-size: contain;

    @include mq(medium-major) {
        background-position: top left;
        background-size: contain;
        width: 180px;
        height: 34px;
        margin: 0;
        width: 100%;
    }
}

@if $theme == "amc" {
    .global-branding {
        background-image: url(../images/AMC-white.png);
        background-position: 50% 50%;
    }
}

@if $theme == "masseyhall" {
    .global-branding {
        background-image: url(../images/logo-massey-hall.svg);

        @include mq(medium-major) {
            background-image: url(../images/logo-massey-hall-desktop.svg);
        }
    }
}

@if $theme == "roythomsonhall" {
    .global-branding{
        background-image: url(../images/logo-roy-thomson-hall.svg);

        @include mq(medium-major) {
            background-image: url(../images/logo-roy-thomson-hall-desktop.svg);
        }
    }
}

@if $theme == "tdmusichall" {
    .global-branding{
        background-image: url(../images/logo-td-music-hall.png);
        background-position: 50% 50%;

        @include mq(medium-major) {
            background-size: 80%;
        }
    }
}

@if $theme == "thecorporation" {
    .global-branding {
        background-image: url(../images/logo-corporation.svg);

        @include mq(medium-major) {
            height: 34px;
            width: 100%;
            background-position: 50%;
        }
    }
}

@if $theme == "soundboard" {
    .global-branding {
        background-image: url(../images/logo-corporation.svg);
        height: 30px;
        width: 250px;

        @include mq(medium-major) {
            height: 34px;
            width: 100%;
            background-position: 50%;
        }
    }
}


.global-sidebar__donation {
    margin-bottom: 20px;
    display: none;

    @include mq(medium-major) {
        display: block;
    }
}

.global-donation {
    display: block;
    margin: 0 20px;
    background-color: #333;
    border-color: #333;

    &:hover {
        background-color: #444;
        border-color: #444;
    }
}


/* ==========================================================================
   Global Sidebar - Mobile Actions
   ========================================================================== */

.global-sidebar__mobile-actions {
    position: relative;

    @include mq(medium-major) {
        display: none;
    }

    .nav-toggle {
        position: absolute;
        top: 12px;
    }

    .nav-toggle--menu {
        left: 5%;
        z-index: z-scale(overlay) - 1;
    }

    .nav-toggle--search {
        right: 5%;
        z-index: z-scale(overlay) + 1;
    }
}

/* ==========================================================================
   Section Search - Sidebar Search
   ========================================================================== */

.global-sidebar__search {
    display: none;
    width: 100%;
    padding: 4px 5%;
    font-size: 22px;
    background: #333;

    @include mq(medium-major) {
        display: block;
        position: relative;
        padding: 0 20px;
        margin-top: 0;
        font-size: 18px;
        background-color: black;
    }

    &.is-toggled {
        display: block;
    }
}

.site-search {
    .o-icon {
        @include mq($until: medium-major) {
            display: none;
        }
    }
}
.site-search__input {
    border: none;
    margin-left: 2px;
    font-weight: bold;
    display: inline-block;
    width: 100%;
    background: transparent;
    font-family: font-stack(sans);
    color: inherit;
    &::placeholder { color: inherit; }

    @include mq(medium-major) {
        width: 105px;
    }
}

/* ==========================================================================
   Global Sidebar - Navigation
   ========================================================================== */

.global-sidebar__navigation {
    padding: 20px 5%;
    display: none;
    font-size: 18px;
    color: get-color(text, reversed);

    &.is-toggled {
        display: block;
        position: absolute;
        z-index: 300;
        width: 100%;
        background: get-color(brand, secondary);
        height: 100%;
        z-index: 200;
    }

    @include mq(medium-major) {
        display: block;
        border-top: none;
        font-size: 13px;
        padding: 20px;
    }
}
.sidebar-navigation__inner {
    @include mq(medium-major) {
        width: auto;
        margin-top: 30px;

        @media (min-height: 651px) {
            margin-top: 60px;
        }
    }
}

/* ==========================================================================
   Global Sidebar - Social
   ========================================================================== */

.global-sidebar__social {
    margin-top: $global-baseline;
    padding-top: $global-baseline;
    border-top: 1px solid #333;
    color: get-color(text, reversed);

    .o-icon {
        width: 14px;
        height: 14px;
    }


    @include mq(medium-major) {
        position: absolute;
        bottom: 20px; left: 20px;
        width: 140px;

        @media (max-height: 650px) {
            position: relative;
            bottom: auto; left: auto;
            margin-top: 60px;
        }
        .o-block-list__item {
            font-size: 12px;
        }
    }
    @include mq(xlarge) {
        left: 30px;
        width: 140px;

        @media (max-height: 650px) {
            left: auto;
        }
    }
}

/* ==========================================================================
   Global Sidebar - Newsletter
   ========================================================================== */

.sidebar-newsletter {
    @include mq($until: medium-major) {
        position: fixed;
        bottom: 20px;
        left: 0;
        width: 100%;
    }
}

/* stylelint-disable */
.sidebar-newsletter__inner {
    display: flex;
    flex-direction: column;
    margin-top: $global-baseline;
    padding-top: $global-baseline;
    border-top: 1px solid #333;
    gap: $global-baseline 40px;

    @include mq($until: medium-major) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: $global-baseline 5% 0;
    }
}

.sidebar-newsletter__content {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
}

.sidebar-newsletter__title {
    font-size: 14px;
    margin: 5px 0 0;

    @include mq($until: medium-major) {
        font-size: 16px;
    }
}

.sidebar-newsletter__description {
    font-family: font-stack(body);
    font-size: 14px;
    margin-bottom: 0;
}

.sidebar-newsletter__btn {
    @include mq(medium-major) {
        font-size: 12px;
    }
}
