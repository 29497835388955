/* ==========================================================================
   Base
   ========================================================================== */

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

html {
    height: 100%;
    font-size: 100%;
    line-height: 1.5;
}

body {
    color: get-color(text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/**
 * Sticky footer
 * https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
*/
.global-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    // This turns off the sticky footer feature for IE10 and IE11
    // As min-height needs to be explicit, therefore breaking all other browsers
    // More here: https://thatemil.com/blog/2013/11/03/sticky-footers-flexbox-and-ie10/

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
    }
}
.page-content {
    flex: 1;
}

/* ==========================================================================
   Typography
   ========================================================================== */

h1, h2, h3, h4, h5, h6, .h6, .ff-display {
    font-family: font-stack(display);
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 .5em;
    color: inherit;

    @if $theme == "masseyhall" {
        letter-spacing: 0.5px;
    }
}

h1 {
    @include font-scale(display, 6);
    font-weight: get-font-weight(display, 6);
}
h2 {
    @include font-scale(display, 5);
    font-weight: get-font-weight(display, 5);
}
h3 {
    @include font-scale(display, 4);
    font-weight: get-font-weight(display, 4);
}
h4 {
    @include font-scale(display, 3);
    font-weight: get-font-weight(display, 3);
}
h5 {
    @include font-scale(display, 2);
    font-weight: get-font-weight(display, 2);
}
h6 {
    @include font-scale(display, 1);
    font-weight: get-font-weight(display, 1);
}

/* .ff-body Chained to avoid fonts-loaded  specificity */
html, body, input, select, textarea,
.ff-body.ff-body {
    font-family: font-stack(body);
}

.ff-caption.ff-caption {
    font-family: font-stack(caption);
}

pre, code, kbd, samp,
.ff-monospace.ff-monospace {
    font-family: font-stack(monospace);
}

/*  =========================================================================
    Grouping content
    http://developers.whatwg.org/grouping-content.html
    ========================================================================= */
p,
pre,
blockquote,
ul,
ol,
dl,
figure,
table,
fieldset {
    margin: 0;
    margin-bottom: .75em;
}

ol,
ul {
    padding-left: 1.5em;
    list-style-position: outside;
}
li > ul,
li > ol {
    margin-bottom: 0;
}
ul {
    list-style-type: square;
}

blockquote {
    margin: 0;
}

figure,
figcaption {
/*  Correct `block` display for `figure` and `figcaption` elements in IE 8/9 */
    display: block;
}

/*  =========================================================================
    Text-level semantics
    http://developers.whatwg.org/text-level-semantics.html
    ========================================================================= */

small {
/*  Address inconsistent and variable font size in all browsers */
    font-size: 90%;
    letter-spacing: 0;
    font-weight: normal;
    font-family: inherit;
}

dfn {
/*  Address styling not present in Safari 5 and Chrome */
    font-style: italic;
}

abbr[title],
dfn[title] {
    cursor: help;
    border-bottom: 1px dotted;
}

sub, sup {
/*  Position subscript and superscript content without affecting line-height */
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup { top: -0.5em; }
sub { bottom: -0.25em; }

::-moz-selection {
    background: #b3d4fc;
/*  Remove text-shadow in selection highlight */
    text-shadow: none;
}
::selection {
    background: #b3d4fc;
/*  Remove text-shadow in selection highlight */
    text-shadow: none;
}

/*  =========================================================================
    Links
    http://developers.whatwg.org/links.html
    ========================================================================= */

a {
    color: inherit;
    font-weight: normal;
    line-height: inherit;
    word-wrap: break-word;
    text-decoration: none;
/*  Remove gray background color from active links in IE 10 */
    background-color: transparent;

    &:active,
    &:hover {
    /*  Improve readability when focused and mouse hovered in all browsers */
        outline: 0;
        cursor: pointer;
    }

    &:focus {
        outline: 2px solid get-color(global, focus);
    }
}

/*  =========================================================================
    Embedded content
    http://developers.whatwg.org/embedded-content-1
    ========================================================================= */

img, picture, embed, object, video {
/*  Remove border when inside `a` element in IE 8/9 */
    display: block;
    border: 0;
    max-width: 100%;
}

img[width],
img[height] {
/*  Non-fluid images if you specify `width` and/or `height` attributes. */
    max-width: none;
}

svg:not(:root) {
/*  Correct overflow displayed oddly in IE9 */
    overflow: hidden;
}

iframe {
    border: none;
}
