/*  =========================================================================
    Scopes: Prose
    ========================================================================= */
/**
 *  SilverStripe UserForms module generates markup so rather than spending
 *  time overriding everything let's adknowledge this and scope our styles.
 */
.userform {
    /**
     * UserForms is a bit fieldset heavy, so we need to be
     * selective in which legends we style like titles.
     */
    fieldset.CompositeField > legend {
        font-family: font-stack(display);
        @include font-scale(display, 3);
        margin-bottom: 10px;
    }

    /**
     * Required fields.
     * UserForms doesn't output any specific text
     * for required fields so we need to style them somehow.
     */
    .field.requiredField > label,
    fieldset.requiredField > legend {
        &:after {
            content: ' (required)';
            display: inline-block;
            font-weight: normal;
            margin-left: 5px;
            color: get-color(text, note);
        }
    }

    .field {
        margin-bottom: 1em;
    }

    /**
     * Groups of options (checkboxes, radios) are in a fieldset
     * but regular field items use labels.
     * Let's visually treat them the same.
     */
    .field > label,
    .field > legend {
        font-weight: bold;
    }

    /**
     * Basic text input types that are allowed
     * by UserForms
     */
    input[type=text],
    input[type=email],
    input[type=tel] {
        display: block;
        width: 100%;
        padding: 7px;
        margin-bottom: 3px;
        border-radius: 0;
        border: 1px solid #ccc;
        -webkit-appearance: none;
    }

    /**
     * List of checkboxes or radio buttons.
     */
    ul.optionset {
        @include reset-list();
    }

    /**
     * Style radios and checkboxes to give
     * then a larger hit area.
     */
    .checkbox .middleColumn,
    ul.optionset li {
        display: table;
        width: 100%;
        padding: 2%;
        margin-bottom: 1px;
        background-color: get-color(neutral, light);
        font-family: font-stack(sans);
        display: table;
        width: 100%;

        input {
            margin-right: 10px;
        }

        label {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            font-size: 14px;
        }
    }

    /**
     * Actions contains submit
     * buttons for the form.
     */
    .Actions {
        padding: 10px 0;
        border-top: 1px solid get-color(neutral);
    }

    /**
     * "RightTitle" in SilverStripe refers to
     * help or description text.
     */
    .right-title {
        display: block;
        font-family: font-stack(caption);
        color: get-color(text, note);
        margin-bottom: 3px;
    }

    /**
     * Inline field error messages.
     */
    .message {
        display: block;
        font-weight: bold;
        padding: 7px;
        margin: 5px 0;
        background-color: get-color(message);
        font-family: font-stack(sans);
        font-size: 14px;
    }
    .message.error {
        color: contrasting(get-color(message, warning), white, black);
        background-color: get-color(message, warning);
    }
}
