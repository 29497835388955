.global-container.password-protection {
    background: black;
    margin: 0;
    color: #fff;
    text-align: center;
    padding: 1em;
    width: 100%;
    position: relative;
}

.password-protection {
    .page-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }

    .header {
        @include mq(medium-major) {
            width: 30%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
        }
    }

    .branding {

        background-image: url(../images/logo-corporation.svg);
        display: inline-block;
        vertical-align: middle;
        margin: auto;
        background-repeat: no-repeat;
        background-position: center;
        height: 50px;
        width: 250px;
        background-size: contain;
        margin-bottom: 2em;

        @include mq(medium-major) {
            background-position: top left;
            background-size: contain;
            width: 72px;
            margin: 0;
        }
    }

    form {
        width: 80%;
        margin: 0 auto;

        @include mq(medium-major) {
            display: flex;
            align-items: center;
        }
    }
}

.password-protection__title{
    p{ margin-bottom: 0; }
    b{ font-weight: 500; }
}

.password-protection__description {
    margin-bottom: 1.75em;
    padding: 0 2em;
    @include font-scale(body, 3);
}

.password-protection__form{
    margin: 1em auto;
    width: 95%;
    background: #201d1e;
    padding: 40px 0;

    @include mq(medium-major) {
        width: 33%;
    }
}

.password-protection__input {
    height: 38px;
    line-height: 38px;
    width: 100%;
    font-size: 20px;
    color: black;
    display: block;
    border: none;
    border-radius: 5px;
    text-align: center;
    font-family: font-stack(body);
    height: 52px;

    @include mq(medium-major) {
        width: 40%;
        flex-grow: 1;
        width: 70%;
        border-radius: 5px 0 0 5px;
        margin-right: 5px;
    }
}

.password-protection__btn {
    margin-top: 1em;
    display: block;
    width: 100%;
    height: 52px;

    @include mq(medium-major) {
        margin-top: 0;
        width: 30%;
    }
}
