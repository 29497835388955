/* ==========================================================================
   Forms
   ========================================================================== */

form {
    margin: 0;
}
legend {
    padding: 0;
    display: table;
}
fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
}
body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}

/* ==========================================================================
   Forms: Buttons
   ========================================================================== */

[type=button],
[type=reset],
[type=submit] {
    width: auto;
    height: auto;
    border-radius: 0;
}

/* ==========================================================================
   Forms: Labels & Inputs
   ========================================================================== */

label,
.label {
    display: inline;
    font-weight: bold;
    cursor: pointer;
}

/**
 * Fix a bug Internet Explorer 8 where text in password fields
 * would become invisible when `font-family` references a webfont.
 *
 * @group forms
 */
[type=password] {
    font-family: sans-serif;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    border-radius: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
input[type=search] {
    -webkit-appearance: none;
}

.input-text,
.input-textarea,
textarea {
    display: inline-block;
    box-sizing: border-box;
    padding: 4px;
    border: 1px solid get-color(neutral);
    border-radius: 1px;
    width: 100%;

    &:focus {
        outline: 0;
        border-color: darken(get-color(neutral), 20%);
    }
}
.input-text--small {
    width: 30%;
}
.input-text--xsmall {
    width: 15%;
}
.input-textarea,
textarea {
    height: auto;
    resize: vertical;
}

[type=file]:focus,
[type=checkbox]:focus {
    outline: 1px dotted #666;
}

select,
.select {
    font: inherit;
    max-width: 100%;

    &:focus {
        outline: 1px dotted #666;
    }
    /**
     * Make multiple select elements height not fixed
     */
    &[multiple] {
        height: inherit;
    }
}
.select--wide {
    width: 100%;
}

/* ==========================================================================
   Forms: Fields
   ========================================================================== */

.form-field {
    margin-bottom: 1em;
}
.form-field--checkbox label {
    font-weight: normal;
}

/* ==========================================================================
   Forms: Input Group
   ========================================================================== */

.input-group {
    position: relative;
    font-family: font-stack(sans);
}
.input-group__title{
    font-weight: bold;
    padding: 10px 0;
}
.input-group__field,
.input-group__action {
    box-sizing: border-box;
    height: 30px;
    line-height: 30px;
}
.input-group__field {
    width: 100%;
    padding-right: 4em;
}
.input-group__action {
    position: absolute;
    top: 0; right: 0;
    margin-left: -0.31em;
    border-radius: 0;
}

/* ==========================================================================
   Forms: Inline Newsletter
   ========================================================================== */

.inline-newsletter {
    display: flex;
    flex-wrap: wrap;
}

.inline-newsletter__block {
    margin-bottom: 10px;
    width: 100%;
}
.inline-newsletter__block--first-name,
.inline-newsletter__block--last-name,
.inline-newsletter__block--email {
    border: 1px solid get-color(global, border);
}
.inline-newsletter__field {
    display: flex;
}
.inline-newsletter__field__input {
    height: 36px;
    line-height: 36px;
    border: none;
    flex: 1;
    flex-shrink: 0;
    flex-basis: auto;
    color: #232323;
    border-radius: 0;

}
.inline-newsletter__field__addon {
    height: 36px;
    width: 36px;
    background-color: white;
    color: get-color(neutral, dark);
    padding: 2px 6px;
    font-size: 20px;
    float: left;
}
@include mq(small) {
    .inline-newsletter__block--first-name,
    .inline-newsletter__block--last-name {
        width: 49%;
    }
    .inline-newsletter__block--last-name {
        margin-left: auto;
    }
    .inline-newsletter__block--email {
        width: calc(100% - 122px);
    }
    .inline-newsletter__block--button {
        width: 112px;
        margin-left: auto;
        text-align: right;
    }
}
