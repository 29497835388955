//  -------------------------------------------------------- //
//  Typography
//  -------------------------------------------------------- //

@function font-stack($key) {
    @return unquote(map-get($font-stacks, $key));
}

@function get-font-scale($stack, $index) {
    @return map-get(map-get($font-scales, $stack), $index);
}

@function get-font-size($stack, $index) {
    @return map-get(get-font-scale($stack, $index), font-size);
}

@function get-line-height($stack, $index) {
    @return map-get(get-font-scale($stack, $index), line-height);
}

@function get-font-weight($stack, $index) {
    @return map-get(get-font-scale($stack, $index), font-weight);
}

@mixin font-scale($stack, $index) {
    font-size: get-font-size($stack, $index);
    line-height: get-line-height($stack, $index);
}

@mixin font-scale-classes($scales: $font-scales, $prefix: "u-font-scale-") {
    @each $name, $scale in $scales {
        @each $index, $stack in $scale {
            .#{unquote($prefix + $name + '-' + $index)} {
                @include font-scale($name, $index);
            }
        }
    }
}
