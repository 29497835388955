/* ==========================================================================
   Utilities: Layout
   ========================================================================== */

.u-constrained {
    @include constrained();
}
.u-fullwidth {
    display: block;
    width: 100%;
}
.u-fixed{
    position: fixed;
    width: 100%;
}
.u-inset {
    padding-top: $global-baseline;
    padding-bottom: $global-baseline;
}
.u-padded {
    padding: $global-baseline;
}

.u-ratio {
    position: relative;
    height: 0;
/*  Maintain layout, based on 4:3 aspect-ratio */
    padding-bottom: aspect-ratio(4,3);
}
.u-ratio--5-3 {
/*  Maintain layout, based on 5:3 aspect-ratio */
    padding-bottom: aspect-ratio(5,3);
}
.u-ratio--3-2 {
/*  Maintain layout, based on 3:2 aspect-ratio */
    padding-bottom: aspect-ratio(3,2);
}
.u-ratio--16-9 {
/*  Maintain layout, based on 16:9 aspect-ratio */
    padding-bottom: aspect-ratio(16,9);
}
.u-ratio--4-1 {
/*  Maintain layout, based on 4:1 aspect-ratio */
    padding-bottom: aspect-ratio(4,1);
}
.u-ratio--10-3 {
/*  Maintain layout, based on 10:3 aspect-ratio */
    padding-bottom: aspect-ratio(10,3);
}
.u-ratio--20-7 {
/*  Maintain layout, based on 20:7 aspect-ratio */
    padding-bottom: aspect-ratio(20,7);
}
.u-ratio--10-7 {
/*  Maintain layout, based on 20:7 aspect-ratio */
    padding-bottom: aspect-ratio(10,7);
}
.u-ratio--square {
/*  Maintain layout, based on square aspect-ratio */
    padding-bottom: aspect-ratio(1,1);
}

.u-button-reset {
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
}
