/* ==========================================================================
   Navigation
   ========================================================================== */

/* ==========================================================================
   Navigation: Nested Menu
   ========================================================================== */

.nested-menu {
    margin-bottom: $global-baseline;
}
.nested-menu__list {
    @include reset-list();
}
.nested-menu__link {
    display: block;
    padding: 0;
    margin-bottom: 0;
}
.nested-menu__link:hover,
.nested-menu__link.is-current-link,
.nested-menu__link.is-current-section {
    color: $primaryColor;
}
.nested-menu__list.is-level-2 {
    margin: .25em 0 .75em;

    .nested-menu__link {
        padding-left: 1.5em;
        font-size: 85%;
    }
}

/* ==========================================================================
   Navigation: Split Navigation
   ========================================================================== */

.nav-split {
    @include clearfix();
    font-size: 85%;
    margin: 1.5em 0 1em;
    padding-top: .5em;
    border-top: 1px solid get-color(neutral);
}
.nav-split__prev,
.nav-split__next {
    display: block;
    margin-bottom: .5em;
}
@include mq(small) {
    .nav-split__prev,
    .nav-split__next {
        margin: 0;
    }
    .nav-split__prev { float: left; }
    .nav-split__next { float: right; }
}

/* ==========================================================================
   Navigation: Pagination
   ========================================================================== */

.pagination {
    @include reset-list();
    clear: both;
    margin: 1.5em 0;
    border-width: 1px 0 0 0;
    text-align: center;
}
.pagination__item,
.pagination__number {
    display: inline-block;
}
.pagination__number {
    font-weight: normal;
    color: get-color(neutral, dark);
    padding: .5em;
    width: 40px;
    position: relative;
    top: -1px;
    background-color: get-color(neutral, light);
}
.pagination__previous, .pagination__next{
    background-color: transparent;
    width: auto;
    opacity: 0.5;

    &:hover{
        background-color: transparent;
    }

    &.is-active{
        opacity: 1;
    }
}
.pagination__link {
    font-weight: bold;
}
.pagination__link:hover {
    color: inherit;
    background-color: get-color(neutral);
}
.pagination__current,
.pagination__current:hover  {
    color: white;
    background-color: $primaryColor;
    border-color:$primaryColor;
    font-weight: bold;
}

/* ==========================================================================
   Navigation: Global Sidebar
   ========================================================================== */

.section-navigation, .support-navigation {
    @include reset-list();
}

.support-navigation {
    margin-top: $global-baseline;
    padding-top: $global-baseline;
    border-top: 1px solid #333;
    display: none;

    @include mq(medium-major) {
        display: block;
    }
}

.section-navigation__item, .support-navigation__item {
    margin-bottom: 5px;
}

.section-navigation__item a {
    opacity: 0.75;
    color: #fff;

    &:hover {
        color: $primaryColor;
        opacity: 1;

         @if $theme == "thecorporation" {
            color: #fff;
        }
    }
}


.section-navigation__item.current a {
    opacity: 1;
    color: #fff;
    &:hover {
        opacity: 1;
    }
}

.section-navigation__item--revitalization a {
    font-weight: bold;
    font-style: italic;
}

/* ==========================================================================
   Navigation: Child Page List
   ========================================================================== */

.child-page-list {
    margin: 0;
    padding-bottom: 2em;

    @include mq(large) {
        margin: 0 auto;
    }

}
.child-page-item {
    @include clearfix();
    display: block;
    margin-bottom: -1px;
    padding: $global-baseline/2 0;
    width: 100%;
    position: relative;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    @include mq(medium) {
        padding: $global-baseline 0;
    }
}

.child-page-item:hover {
   border-color: #000;
   z-index: 100;
}

.child-page-item__media img {
    transition: transform 2s ease-in-out;
}

.child-page-item:hover .child-page-item__media img {
    transform: scale(1.2);
}

.child-page-item__title{
    @include font-scale(display, 6);
    margin: 0;
}
.child-page-item__media {
    width: 120px;
    float: left;

    .no-js & {
        display: none;
    }
}
.child-page-item__content{
    padding: ($global-baseline);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 82px;
    @include mq(medium) {
        padding: ($global-baseline*2);
    }
}
.child-page-item__body{
    position: relative;
    max-width: 540px;
}

.child-page-item__description{
    margin: 10px 0 0;
    display: none;
}

@include mq(small-major) {
    .child-page-item__media{
        width: 300px;
        overflow: hidden;
    }
    .child-page-item__content{
        min-height: 200px;
    }
    .child-page-item__title{
        @include font-scale(display, 5);
    }
    .child-page-item__description{
        display: block;
    }
}
@include mq(medium) {
    .child-page-item__title{
        @include font-scale(display, 6);
    }
}
@include mq(medium) {
    .child-page-item:nth-child(even){
        .child-page-item__content{
            right: 0; left: 0;
        }

        .child-page-item__media {
            float: right;
        }
    }
}

.child-page-item.has-image{
    .child-page-item__content{
        width: calc(100% - 120px);
        float: left;
    }
    @include mq(medium) {
        &:nth-child(even){
            .child-page-item__content{
                float: right;
            }
        }
    }

    @include mq(small-major) {
        .child-page-item__content{
            width: calc(100% - 300px);
        }
    }
}


