/* ==========================================================================
   Articles
   ========================================================================== */

.article-summary {
    @include clearfix();
    margin-bottom: 1em;
    border: 1px solid get-color(global, border);
    padding: .5em;

    .s-prose &{
        a:not(.inline-button), a:not(.inline-button):hover { text-decoration: none; }
    }
}

.article-summary__content {
    padding: .5em;
}
.article-summary__meta {
    margin-bottom: .5em;
}
.article-summary__title {
    font-size: 22px;
    font-family: font-stack(body);
    text-transform: none;

    a, a:hover { color: get-color(text) !important; }
}
.article-summary__author {
    font-style: italic;
}
.article-summary__media {
    @include clearfix();
    display: block;
    margin-bottom: .5em;
}

.article-summary__read-more {
    font-size: 16px;
    display: inline-block;
}

@include mq(small) {
    .article-summary__media {
        float: right;
        width: 23%;
        margin-left: 2%;
        margin-bottom: 0;
    }
}

