/* ==========================================================================
   Objects: Lists
   ========================================================================== */

.o-block-list {
    @include reset-list();
}
.o-block-list__item,
.o-block-list__link {
    display: block;
}
.o-block-list__item {
    margin-bottom: $global-baseline/2;
    font-size: 14px;
    line-height: 1.2;
}

.o-block-list__link {
    padding-left: 20px;
    position: relative;
    &:hover {
        text-decoration: underline;
    }
}

.o-block-list__link .o-icon{
    position: absolute;
    left: 0;
    top: 2px;
}

.o-inline-list {
    @include reset-list();
}
.o-inline-list__item {
    display: inline-block;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }
}
.o-inline-list__link {
    display: block;
}
