/* ==========================================================================
   Landing Page - Header
   ========================================================================== */

.landing-page-header {
	width: 100%;
	background-size: cover;
	background-position: center;
	display: flex;
	position: relative;
	color: #fff;
}

.landing-page-header__link {
	display: block;
	width: 100%;
	padding: 22px;
	display: flex;
	align-items: flex-end;
	min-height: 60vh;

	@include mq(medium) {
		padding: 40px;
		min-height: 70vh;
	}
}

.landing-page-header__link:after {
	content: "";
	background: linear-gradient(to top, #14120f 0%, transparent 100%);
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 60vh;
	z-index: z-scale(foreground);

	@include mq(medium) {
		height: 30vh;
	}
}

.landing-page-header__body {
	position: relative;
	z-index: z-scale(overlay);
	display: inline-block;
}

.landing-page-header--black {
	color: #000;
}

// .landing-page-header__title {
// 	@include font-scale(display, 7);
// 	color: $primaryColor;
// 	line-height: 0.9em;
// 	margin-bottom: 10px;
// 	padding-bottom: 10px;
// 	border-bottom: 6px solid $primaryColor;
// 	display: inline-block;
// 	transition: font-size .5s ease;

// 	@include mq(medium) {
// 		@include font-scale(display, 8);
// 		line-height: 0.9em;
// 	}
// 	@include mq(large) {
// 		@include font-scale(display, 9);
// 		line-height: 0.9em;
// 	}
// }

.landing-page-header__title {
	text-indent: -999999px;
	background-image: url(../images/massey-hall-forever.svg);
	background-size: contain;
	background-repeat: no-repeat;
	width: 180px;
	height: 150px;

	@include mq(small-major) {
		width: 280px;
		height: 250px;
	}
}

.landing-page-header__title .white {
	color: #fff;
	display: block;
}

.landing-page-header__description {
	@include font-scale(body, 1);
	font-family: font-stack(domaine);
	font-weight: 600;

	@include mq(medium) {
		@include font-scale(body, 2);
	}
}

.landing-page-header__cta {
	font-family: font-stack(display);
	background-color: transparent;
	border: none;
	padding: 0;
}

.landing-page-cta {
	position: fixed;
	bottom: 0;
	padding: 5px;
	background-color: #fff;
	width: 100%;
	z-index: z-scale(navigation) + 1;

	@include mq(medium-major) {
		width: calc(100% - 180px);
	}

	@include mq(large) {
		position: sticky;
		top: 83px;
		bottom: auto;
		padding: 0;
		text-align: right;
		height: 0;
		width: 100%;
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		position: absolute;
		top: auto;
	}
}
.landing-page-cta__button {
	padding: 10px 20px;
	display: block;

	@include mq(large) {
		@include font-scale(body, 2);
		margin: -140px 40px 0;
		display: inline-block;
		padding: 8px 20px;
	}
}


/* ==========================================================================
   Landing Page - Navigation
   ========================================================================== */

.landing-page-nav {
	background-color: #292828;
	color: #fff;
	font-family: font-stack(sans);
	position: sticky;
	top: -1px;
	z-index: z-scale(navigation);
}

@include mq($until: large) {
	.landing-page-nav:after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 100px;
		background: linear-gradient(to left, #333 0, transparent 100%);
	}
}

.landing-page-nav__list {
	@include reset-list();
	text-align: center;
    padding: 12px 100px 12px 0;
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.landing-page-nav__item {
	border-right: 1px solid #707070;
	display: inline-block;
}

.landing-page-nav__item:last-child {
	border-right: 0;
}

.landing-page-nav__link {
	padding: 4px 20px;
	margin: 0 10px;
	display: block;
	transition: background-color .5s ease;
	@include font-scale(body, 1);
}

.landing-page-nav__link:hover {
	background-color: lighten(#292828, 10%);
}

/* ==========================================================================
   Landing Page - Introduction
   ========================================================================== */

.landing-page-introduction {
	background-color: #333;
	color: #fff;
	text-align: center;
	padding: 20px;
	-webkit-font-smoothing: antialiased;

	@include mq(medium) {
		padding: 40px;
	}
}

.landing-page-introduction p {
	line-height: 1.5em;
	margin-bottom: 1.2em;
	@include font-scale(body, 2);

	@include mq(medium) {
		@include font-scale(body, 3);
	}
}

// .landing-page-introduction__inner {
// 	@include constrained();
// 	max-width: 950px;
// }

/* ==========================================================================
   Landing Page - Features
   ========================================================================== */

.landing-page-feature {
	background-size: cover;
	background-position: center;
	color: #fff;
	display: flex;
}

.landing-page-feature--right {
	background-position: center right -80px;

	@include mq(medium) {
		background-position: center right;
	}
}

.landing-page-feature--black {
	color: 000;
}

.landing-page-feature__link {
	display: block;
	width: 100%;
	display: flex;
	position: relative;
	flex-direction: column;

	@include mq(medium) {
		flex-direction: row;
		align-items: center;
	}
}

.landing-page-feature__link:after {
	content: "";
	background: linear-gradient(to top, #14120f 0%, transparent 100%);
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 20vh;
	z-index: z-scale(foreground);
}

.landing-page-feature--no-gradient .landing-page-feature__link:after {
    content: none;
}

.landing-page-feature__media {
	@include mq(medium) {
		width: 55%;
	}
}

.landing-page-feature__image {
	width: 100%;
}

.landing-page-feature__body {
	position: relative;
	z-index: z-scale(overlay);
	padding: 40px 20px;
	padding: 30px 20px 40px;

	@include mq(medium) {
		padding: 0 60px 0 40px;
		width: 45%;
	}
}

.landing-page-feature__title {
	@include font-scale(display, 6);
	line-height: 0.9em;
	margin: 0 0 20px;
	transition: font-size .5s ease;

	@include mq(medium) {
		@include font-scale(display, 7);
		line-height: 0.9em;
	}
	@include mq(large) {
		font-size: 46px;
	}
}

.landing-page-feature__subtitle {
	color: $primaryColor;
	margin: 0 0 10px -40px;
	padding: 0 60px 10px 40px;
	border-bottom: 6px solid $primaryColor;
	display: inline-block;
	@include font-scale(display, 3);
	transition: font-size .5s ease;

	@include mq(medium) {
		@include font-scale(display, 5);
	}
	@include mq(large) {
		@include font-scale(display, 6);
	}
}

.landing-page-feature__description {
	@include font-scale(body, 1);
	font-family: font-stack(domaine);
	margin-bottom: 15px;
	padding: 0;

	@include mq(medium) {
		@include font-scale(body, 2);
	}
}

.landing-page-feature__cta {
	font-family: font-stack(display);
	background-color: transparent;
	border: none;
	padding: 0;
	display: block;
	@include font-scale(body, 2);

	@include mq(medium) {
		@include font-scale(body, 3);
	}
}

.landing-page-feature--full {
	align-items: flex-end;
	padding-top: 90%;
	height: 0;
	position: relative;

	@include mq(small) {
		padding-top: 60%;
	}

	@include mq(medium) {
		padding-top: 33%;
	}

	.landing-page-feature__link {
		position: absolute;
		height: 100%;
		flex-direction: row;
		align-items: flex-end;
		top: 0;
		left: 0;

		@include mq(medium) {
			padding-bottom: 30px;
		}

		@include mq(1500px) {
			align-items: center;
			padding-bottom: 0;
		}
	}

	.landing-page-feature__link:after {
		height: 40vh;
		@include mq(medium) {
			height: 30vh;
		}
		@include mq(1500px) {
			height: 50vh;
		}
	}

	.landing-page-feature__body {
		width: auto;
		max-width: 550px;
	}

	.landing-page-feature__title {
		margin-bottom: 10px;
	}
	.landing-page-feature__cta {
		color: $primaryColor;
	}
}

.landing-page-feature--quote {
	.landing-page-feature__title {
		margin: 0 0 10px -40px;
		padding: 0 10px 10px 40px;
		border-bottom: 6px solid $primaryColor;
		@include font-scale(display, 5);

		@include mq(large) {
			@include font-scale(display, 7);
		}
	}
	.landing-page-feature__title:before {
		content: "“";
		display: block;
		line-height: .5em;
	}
	.landing-page-feature__title:after {
		content: "”";
		display: block;
		line-height: .5em;
		margin-top: 12px;
	}
}


/* ==========================================================================
   Landing Page - Signup
   ========================================================================== */

.revitalization-signup {
	background-color: $primaryColor;
	padding: 20px 0;
	color: #fff;

	@include mq(medium) {
		padding: 10px 0;
	}
}

.revitalization-signup__form {
	margin: auto;
    width: 100%;
    max-width: 320px;

    @include mq(medium) {
        @include clearfix();
        max-width: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: none;
    }
}

.revitalization-signup__block {
    margin-bottom: 10px;

    @include mq(medium) {
        margin: 0 5px;
        display: inline-block;
    }
}
.revitalization-signup__label {
    text-align: center;
    @include font-scale(display, 2);

    @include mq(medium) {
        text-align: right;
        // Optically align text
        position: relative;
        top: 5px;
        width: 180px;
    }
}
.revitalization-signup__icon__success {
    position: relative;
    top: -4px;
}
.revitalization-signup__field {
    display: flex;
}
.revitalization-signup__field__input {
    height: 36px;
    line-height: 36px;
    border: none;
    flex: 1;
    flex-shrink: 0;
    flex-basis: auto;
    color: #232323;
    border-radius: 0;

    @include mq(medium) {
        max-width: 100px;
    }
    @include mq(large) {
        max-width: 160px;
    }
    @include mq(xlarge) {
        max-width: 200px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 180px;
    }
}
.revitalization-signup__field__addon {
    height: 36px;
    width: 36px;
    background-color: white;
    color: get-color(neutral, dark);
    padding: 2px 6px;
    font-size: 20px;
    float: left;
}
.revitalization-signup__subscribe {
    display: block;
    // Important to override button styles
    width: 100% !important;
    border-radius: 0;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 8px 1em;

    @include mq(medium) {
        width: auto;
        width: 100px;
    }
}

.revitalization-signup__subscribe:hover {
	color: $primaryColor;
    background-color: #fff;
    border: 2px solid #fff;
}
