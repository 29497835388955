/* ==========================================================================
   Series
   ========================================================================== */

/* ==========================================================================
   Series - Header
   ========================================================================== */

.series-header {
    background-color: get-color(brand, secondary);
    color: #fff;
    position: relative;
}
.series-header__branding {
    padding: 10px;
    background-color: get-color(brand, massey);
}
.series-header__media .o-figure{
    margin-bottom: 0;
}

.series-header__content {
    padding: $global-baseline $global-gutter;
    padding-bottom: 0;
}
.series-header__title {
    margin: 20px 0 0 0;
    text-transform: uppercase;
    @include font-scale(display, 5);
}
.series-header__date-description {
    font-weight: bold;
    text-transform: uppercase;
    margin: 20px 0;
    color: $primaryColor;
    padding-bottom: 20px;
    border-bottom: 4px solid $primaryColor;
    font-size: 18px;

    @if $theme == "roythomsonhall" {
        font-family: font-stack(trim);
    }
}
.series-header__availability{
    margin-right: 10px;
    display: inline-block;
}
.series-header__price {
    display: inline-block;
    font-weight: bold;
}
.series-header__social {
    margin-top: 20px;
    position: relative;
}
.series-header__hashtag{
    position: absolute;
    right: 0;
    top: 0;
    font-family: font-stack(sans);
}
.series-header__social .share-tool__button{
    margin-bottom: 1em;

    &.is-toggled{
        margin-bottom: 15px;
    }
}
.series-header__content.has-prefix{
    .series-header__title{ margin: 0; }
}

.series-header.has-image {
    @include clearfix();

    @include mq(medium) {
        .series-header__media {
            width: 45%;
            float: right;

            &.is-transparent {
                margin-top: -5.95%;
            }
        }
        .series-header__content {
            width: 55%;
            float: left;
        }
    }
    @include mq(xlarge) {
         .series-header__media {
            margin-right: 0;
            width: 40%;
        }
        .series-header__content {
            padding:  $global-baseline 5% $global-baseline 0;
            width: 60%;
        }
    }
}

@include mq(medium) {
     .series-header__social{
        position: absolute;
        bottom: 20px;
     }
     .series-header__hashtag{
        float: left;
        position: relative;
        top: auto;
    }
    .series-header__social .share-tool{
        float: left;
        margin-right: 30px;
    }
    .series-header__social .share-tool__button.is-toggled{
        margin-bottom: 1em;
    }
    .series-header__date-description{
        font-size: 22px;
    }
}

@include mq(large) {
    .series-header__title { @include font-scale(display, 7); }
}
@include mq(xlarge) {
    .series-header__inner{ @include constrained(); }
    .series-header__branding { margin: 0 0 20px; }
}

