/* ==========================================================================
   Utilities: Text
   ========================================================================== */

@include font-scale-classes();

.u-initialism {
    font-size: 85%;
    text-transform: uppercase;
}

.u-link-more {
    display: block;
    font-weight: bold;

    &:after {
        content: '\2192';
        margin-left: .5em;
    }

    &:hover {
        text-decoration: underline;
    }
}

.u-block-link {
    display: block;
    height: 100%;
}

.u-list-unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
}
.u-large{
    font-size: 120%;
}
