/* ==========================================================================
   Object: Figures
   ========================================================================== */

.o-figure {
    position: relative;
    margin: 0 0 1em 0;
}
.o-figure__media {
    width: 100%;
}
.o-figure__caption {
    font-size: 70%;
    margin: 0;
    position: absolute;
    bottom: $global-baseline / 2;
    right: $global-baseline / 2;
    padding: .3em .5em;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
}
