/* ==========================================================================
   Autocomplete
   ========================================================================== */

.sey-menu {
    position: static !important;
}
.sey-list {
    display: none;
    position: absolute;
    z-index: z-scale(overlay);
    padding: 0;
    margin: 5px 0 0;
    list-style-type: none;
    color: get-color(text);
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    font-family: font-stack(caption);
    text-shadow: none;
    max-width: 360px;
    @include font-scale(body, 2);

    @include mq(medium) {
        min-width: 355px;
    }
}
.sey-show {
    display: block;
}
.sey-hide {
    display: none;
}
.sey-item {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px;
    text-align: left;
}
.sey-item:hover,
.sey-selected {
    background-color: #b3d4fc;
}
