/* ==========================================================================
   Calendar
   ========================================================================== */

$calendar-height: 312px;

.event-calendar {
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    opacity: 0.5;
    cursor: not-allowed;
    transition: opacity 1s ease;

    &.events-loaded{
        opacity: 1;
        cursor: default;
    }
}

.event-calendar__controls {
    color: get-color(text);
}

.calendar-controls__previous-month,
.calendar-controls__next-month {
    width: 15%;
    height: 35px;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    line-height: 1.2em;
    font-family: font-stack(sans);
    &:hover {
        color: $primaryColor;
    }
}
.calendar-controls__previous-month{ float: left; }

.calendar-controls__next-month{ float: right; }

.calendar-controls__month {
    width: 70%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    text-align: center;
    font-size: 24px;
    font-family: font-stack(display);

    @if $theme == "masseyhall" {
        text-transform: uppercase;
    }
}

.event-calendar__content {
    position: relative;
    width: 100%;
    height: $calendar-height;
    display: inline-block;
    border: 1px solid get-color(global, border);
    box-sizing: border-box;

    &.show-events {
        .calendar-month { left: -100%; }
        .calendar-events { left: 0; }
    }
}

.calendar-month{
    position: absolute;
    left: 0;
    width: 100%;
    height: $calendar-height;
    transition: left 0.25s;
    padding: $global-baseline;
    box-sizing: border-box;

    .day,
    .empty {
        width: 14.28%;
        display: inline-block;
        float: left;
        padding: 8px 0;
        font-size: 16px;
        text-align: center;
        color: #999;
        background-color: #fff;
        border-bottom: 1px solid get-color(global, border);
    }

    .day.adjacent-month,
    .empty.adjacent-month,
    .day.past,
    .empty.past {
        opacity: 0.5;
    }

    .next-month.event {
        color: #000;
    }

    .day.today {
        background-color: $primaryColor;
        color: #fff;

        &.event{
            color: #fff;
        }
    }
    .day.event,
    .empty.event {
        color:  $primaryColor;
        cursor: pointer;
        font-weight: bold;
        &:hover {
            background-color: $primaryColor;
            color: #fff;
        }
    }
}

.calendar-month__days-of-week {
    padding-top: 2px;
}
.calendar-month__day-of-week {
    font-size: 12px;
    width: 14.28%;
    border-bottom: 1px solid #000;
    display: inline-block;
    text-align: center;
    color: get-color(text);
    font-family: font-stack(sans);
    font-weight: bold;
}
.calendar-events {
    position: absolute;
    left: 100%;
    width: 100%;
    height: $calendar-height;
    transition: left 0.25s;
    padding: ($global-baseline/2);
    box-sizing: border-box;
}

.calendar-events__header {
    position: relative;
}
.calendar-events__date{
    width: 100%;
    padding: 0 0 5px 0;
    text-align: center;
    font-family: font-stack(domaine);
    border-bottom: 1px solid #000;
    margin-bottom: 1px;
    text-transform: uppercase;

    @if $theme == "roythomsonhall" {
        font-family: font-stack(trim);
    }
}
.calendar-events__close{
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: color 0.25s;
    display: block;
    line-height: 1;
    padding: 7px;
    font-family: font-stack(sans);

    &:hover {
        color: #999;
    }
}
.calendar-events__events-list {
    overflow-y: scroll;
     -webkit-overflow-scrolling: touch;
    height: 262px;
}
.calendar-events__event-standard{
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    margin-bottom: 1px;
    border-bottom: 1px solid get-color(global, border);
    background-color: #fff;
}

.calendar-events__event-link{
    transition: background-color 0.25s, border-color 0.25s;

    &:hover {
        color: #fff;
        background-color: $primaryColor;
        border-color: $primaryColor;
    }
}
.calendar-events__event-time{
    font-size: 14px;
    margin-right: 1em;
    width: 55px;
    float: left;
}
.calendar-events__event-venue, .calendar-events__event-title{
    display: block;
    padding-left: 70px;
}
.event-calendar__sponsor {
    display: flex;
    align-items: flex-end;
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;
}
.event-calendar__sponsor__title {
    flex-shrink: 0;
    text-align: right;
    line-height: 1em;
    margin-right: 10px;
    margin-left: auto;
    font-size: 14px;
}
.event-calendar__sponsor__image {
    background-image: url(../images/logo-calendar-sponsor.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    width:  130px;
    height: 30px;
}
