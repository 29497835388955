/* ==========================================================================
   Objects: Icons
   ========================================================================== */

.o-icon {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: 20px;
    height: 20px;
}
.o-icon--small {
   width: 12px;
   height: 12px;
}
.o-icon--large {
   width: 26px;
   height: 26px;
}
.o-icon--xlarge {
   width: 42px;
   height: 42px;
}
.o-icon--scale {
    width: .75em;
    height: .75em;
    vertical-align: middle;
    position: relative;
    top: -2px;
}
.o-icon--top {
    vertical-align: top;
}
.o-icon--bottom {
    vertical-align: bottom;
}
.o-icon--offset {
    margin-right: 5px;
}
.o-icon--inline {
    vertical-align: text-bottom;
    margin-right: 5px;
}
.o-icon--muted {
  opacity: .15;
}

