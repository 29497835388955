/* ==========================================================================
   Global Alert
   ========================================================================== */

.global-alert {
    max-height: 0;
    transition: max-height 500ms;
    overflow: hidden;
    margin-bottom: 0;
    line-height: 1.2;
    position: relative;
    color: white;
    background-color: darken($primaryColor, 30%);
    font-family: font-stack(body);
    font-size: 12px;
    @include mq(medium) {
        font-size: 14px;
    }
}
.global-alert.is-visible {
    max-height: 80px;
}
.global-alert__inner {
    @include clearfix();
    padding: .5em;
    padding: 0.75vw;
}
.global-alert__message {
    float: left;
    width: 90%;
    padding-left: 10%;
    text-align: center;
    margin-bottom: 0;
    margin-top: 3px;
}
.global-alert__message a{
    border-bottom: 1px solid #fff;
}
.global-alert__close {
    float: right;
    cursor: pointer;
    padding: 0;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.global-alert--constituents p {
    margin-bottom: 6px;
}

.global-alert--constituents .global-alert__close {
    position: absolute;
    right: 10px;
}
