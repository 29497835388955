/* ==========================================================================
   Revitalization
   ========================================================================== */

.revitalization {
    background-color: get-color(brand, massey);
    padding-bottom: $global-baseline;
    margin-bottom: $global-baseline * 2;
}

.revitalization-roythomsonhall {
    background-color: get-color(brand, thomson);
}

.revitalization__image {
    width: 100%;
}

.revitalization__header,
.revitalization__footer {
    padding: $global-baseline $global-baseline 0;
}

.revitalization__body {
    padding: 0 $global-baseline;
}

.revitalization__title {
    color: #fff;
    font-family: font-stack(trim);
    @include font-scale(display, 5);
    margin-bottom: 5px;

    span {
        color: get-color(text);
        display: block;
    }
}

.revitalization__subtitle {
    color: #fff;
    font-family: font-stack(domaine);
    @include font-scale(display, 2);
    font-style: italic;
    padding-bottom: 10px;
    border-bottom: 4px solid #201d1e;
}

.revitalization__update {
    padding: 12px 0;
    border-bottom: 1px solid #201d1e;
}

.revitalization__update-title {
    @include font-scale(body, 2);
    margin: 0;
    line-height: 1em;
}

.revitalization__link {
    color: #fff;
    display: block;
}

.revitalization__button {
    width: 100%;
}
