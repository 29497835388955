//  -------------------------------------------------------- //
//  Variables
//  -------------------------------------------------------- //

//  -------------------------------------------------------- //
//  Settings
//  -------------------------------------------------------- //

// Global settings
$global-width-maximum: 1200px;
$global-gutter: 5%;
$global-baseline: 16px;
$global-sidebar-width: 180px;
$global-sidebar-width--large: 200px;
$global-offset: 2.25%;

//  -------------------------------------------------------- //
//  Breakpoints
//  -------------------------------------------------------- //
//  There are no common breakpoints so these are just a suggestion
//  You'll need to define your own breakpoints to suit your design.
//  @see https://github.com/sass-mq/sass-mq for usage notes.

$mq-breakpoints: (
    xsmall: 21em,
    small: 30em,
    small-major: 40em,
    medium: 50em,
    medium-major: 62.5em,
    large: 75em,
    xlarge: 90em,
    xxlarge: 100em
);

//  -------------------------------------------------------- //
//  Z-index scale
//  -------------------------------------------------------- //

$z-scale: (
    foreground: 5,
    overlay: 10,
    navigation: 100,
    modal: 150
);

//  -------------------------------------------------------- //
//  Colours
//  -------------------------------------------------------- //

$colors: (
    brand: (
        secondary: #201d1e,
        massey: #ef433e,
        thomson: #9c8b5a,
        venue: #3b3537,
        td: #038203
    ),
    lamh: (
        primary: #4c5277,
        secondary: #e8e7e2
    ),
    neutral: (
        dark: #6a6a6a,
        base: #bfc1c3,
        light: #eaeaea
    ),
    text: (
        dark: #181822,
        base: #201d1e,
        light: #888,
        note: #666,
        reversed: #fff
    ),
    message: (
        base: #ddd,
        success: #3e753f,
        warning: #d00
    ),
    button: (
        success: #3e753f,
        warning: #d00
    ),
    global: (
        header: #201d1e,
        footer: #201d1e,
        focus: #b3d4fc,
        border: #eaeaea,
        background: #000
    )
);

